
:where(.is-layout-flex) {
    gap: 0.5em;
}

body .is-layout-flow>.alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-flow>.alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-flow>.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained>.alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-constrained>.alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-constrained>.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained> :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    // max-width: var($wpStyleGlobalContent-size);
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained>.alignwide {
    // max-width: var($wpStyleGlobalwide-size);
}

body .is-layout-flex {
    display: flex;
}

body .is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
}

body .is-layout-flex>* {
    margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

.has-black-color {
    color: var($wpPresetColorBlack) !important;
}

.has-cyan-bluish-gray-color {
    color: var($wpPresetColorCyan-bluish-gray) !important;
}

.has-white-color {
    color: var($wpPresetColorwhite) !important;
}

.has-pale-pink-color {
    color: var($wpPresetColorPale-pink) !important;
}

.has-vivid-red-color {
    color: var($wpPresetColorVivid-red) !important;
}

.has-luminous-vivid-orange-color {
    color: var($wpPresetColorLuminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
    color: var($wpPresetColorLuminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
    color: var($wpPresetColorLight-green-cyan) !important;
}

.has-vivid-green-cyan-color {
    color: var($wpPresetColorVivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
    color: var($wpPresetColorPale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
    color: var($wpPresetColorVivid-cyan-blue) !important;
}

.has-vivid-purple-color {
    color: var($wpPresetColorVivid-purple) !important;
}

.has-black-background-color {
    background-color: var($wpPresetColorBlack) !important;
}

.has-cyan-bluish-gray-background-color {
    background-color: var($wpPresetColorCyan-bluish-gray) !important;
}

.has-white-background-color {
    background-color: var($wpPresetColorwhite) !important;
}

.has-pale-pink-background-color {
    background-color: var($wpPresetColorPale-pink) !important;
}

.has-vivid-red-background-color {
    background-color: var($wpPresetColorVivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
    background-color: var($wpPresetColorLuminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
    background-color: var($wpPresetColorLuminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
    background-color: var($wpPresetColorLight-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
    background-color: var($wpPresetColorVivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
    background-color: var($wpPresetColorPale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
    background-color: var($wpPresetColorVivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
    background-color: var($wpPresetColorVivid-purple) !important;
}

.has-black-border-color {
    border-color: var($wpPresetColorBlack) !important;
}

.has-cyan-bluish-gray-border-color {
    border-color: var($wpPresetColorCyan-bluish-gray) !important;
}

.has-white-border-color {
    border-color: var($wpPresetColorwhite) !important;
}

.has-pale-pink-border-color {
    border-color: var($wpPresetColorPale-pink) !important;
}

.has-vivid-red-border-color {
    border-color: var($wpPresetColorVivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
    border-color: var($wpPresetColorLuminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
    border-color: var($wpPresetColorLuminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
    border-color: var($wpPresetColorLight-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
    border-color: var($wpPresetColorVivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
    border-color: var($wpPresetColorPale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
    border-color: var($wpPresetColorVivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
    border-color: var($wpPresetColorVivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
    background: var($wpPresetGradientVivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
    background: var($wpPresetGradientLight-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
    background: var($wpPresetGradientLuminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
    background: var($wpPresetGradientLuminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
    background: var($wpPresetGradientVery-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
    background: var($wpPresetGradientCool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
    background: var($wpPresetGradientBlush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
    background: var($wpPresetGradientBlush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
    background: var($wpPresetGradientLuminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
    background: var($wpPresetGradientPale-ocean) !important;
}

.has-electric-grass-gradient-background {
    background: var($wpPresetGradientElectric-grass) !important;
}

.has-midnight-gradient-background {
    background: var($wpPresetGradientMidnight) !important;
}

.has-small-font-size {
    font-size: var($wpPresetFont-sizeSmall) !important;
}

.has-medium-font-size {
    font-size: var($wpPresetFont-sizeMedium) !important;
}

.has-large-font-size {
    font-size: var($wpPresetFont-sizeLarge) !important;
}

.has-x-large-font-size {
    font-size: var($wpPresetFont-sizeX-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
    color: inherit;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

.wp-block-pullquote {
    font-size: 1.5em;
    line-height: 1.6;
}

.ext-absolute {
    position: absolute !important
}

.ext-relative {
    position: relative !important
}

.ext-top-base {
    top: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-top-lg {
    top: var(ExtendifySpacingLarge) !important
}

.ext--top-base {
    top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.ext--top-lg {
    top: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-right-base {
    // right: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-right-lg {
    right: var(ExtendifySpacingLarge) !important
}

.ext--right-base {
    // right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.ext--right-lg {
    right: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-bottom-base {
    // bottom: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-bottom-lg {
    bottom: var(ExtendifySpacingLarge) !important
}

.extBottom-base {
    // bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extBottom-lg {
    bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-left-base {
    // left: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-left-lg {
    left: var(ExtendifySpacingLarge) !important
}

.extLeft-base {
    // left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extLeft-lg {
    left: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-order-1 {
    order: 1 !important
}

.ext-order-2 {
    order: 2 !important
}

.ext-col-auto {
    grid-column: auto !important
}

.ext-col-span-1 {
    grid-column: span 1/span 1 !important
}

.ext-col-span-2 {
    grid-column: span 2/span 2 !important
}

.ext-col-span-3 {
    grid-column: span 3/span 3 !important
}

.ext-col-span-4 {
    grid-column: span 4/span 4 !important
}

.ext-col-span-5 {
    grid-column: span 5/span 5 !important
}

.ext-col-span-6 {
    grid-column: span 6/span 6 !important
}

.ext-col-span-7 {
    grid-column: span 7/span 7 !important
}

.ext-col-span-8 {
    grid-column: span 8/span 8 !important
}

.ext-col-span-9 {
    grid-column: span 9/span 9 !important
}

.ext-col-span-10 {
    grid-column: span 10/span 10 !important
}

.ext-col-span-11 {
    grid-column: span 11/span 11 !important
}

.ext-col-span-12 {
    grid-column: span 12/span 12 !important
}

.ext-col-span-full {
    grid-column: 1/-1 !important
}

.ext-col-start-1 {
    grid-column-start: 1 !important
}

.ext-col-start-2 {
    grid-column-start: 2 !important
}

.ext-col-start-3 {
    grid-column-start: 3 !important
}

.ext-col-start-4 {
    grid-column-start: 4 !important
}

.ext-col-start-5 {
    grid-column-start: 5 !important
}

.ext-col-start-6 {
    grid-column-start: 6 !important
}

.ext-col-start-7 {
    grid-column-start: 7 !important
}

.ext-col-start-8 {
    grid-column-start: 8 !important
}

.ext-col-start-9 {
    grid-column-start: 9 !important
}

.ext-col-start-10 {
    grid-column-start: 10 !important
}

.ext-col-start-11 {
    grid-column-start: 11 !important
}

.ext-col-start-12 {
    grid-column-start: 12 !important
}

.ext-col-start-13 {
    grid-column-start: 13 !important
}

.ext-col-start-auto {
    grid-column-start: auto !important
}

.ext-col-end-1 {
    grid-column-end: 1 !important
}

.ext-col-end-2 {
    grid-column-end: 2 !important
}

.ext-col-end-3 {
    grid-column-end: 3 !important
}

.ext-col-end-4 {
    grid-column-end: 4 !important
}

.ext-col-end-5 {
    grid-column-end: 5 !important
}

.ext-col-end-6 {
    grid-column-end: 6 !important
}

.ext-col-end-7 {
    grid-column-end: 7 !important
}

.ext-col-end-8 {
    grid-column-end: 8 !important
}

.ext-col-end-9 {
    grid-column-end: 9 !important
}

.ext-col-end-10 {
    grid-column-end: 10 !important
}

.ext-col-end-11 {
    grid-column-end: 11 !important
}

.ext-col-end-12 {
    grid-column-end: 12 !important
}

.ext-col-end-13 {
    grid-column-end: 13 !important
}

.ext-col-end-auto {
    grid-column-end: auto !important
}

.ext-row-auto {
    grid-row: auto !important
}

.ext-row-span-1 {
    grid-row: span 1/span 1 !important
}

.ext-row-span-2 {
    grid-row: span 2/span 2 !important
}

.ext-row-span-3 {
    grid-row: span 3/span 3 !important
}

.ext-row-span-4 {
    grid-row: span 4/span 4 !important
}

.ext-row-span-5 {
    grid-row: span 5/span 5 !important
}

.ext-row-span-6 {
    grid-row: span 6/span 6 !important
}

.ext-row-span-full {
    grid-row: 1/-1 !important
}

.ext-row-start-1 {
    grid-row-start: 1 !important
}

.ext-row-start-2 {
    grid-row-start: 2 !important
}

.ext-row-start-3 {
    grid-row-start: 3 !important
}

.ext-row-start-4 {
    grid-row-start: 4 !important
}

.ext-row-start-5 {
    grid-row-start: 5 !important
}

.ext-row-start-6 {
    grid-row-start: 6 !important
}

.ext-row-start-7 {
    grid-row-start: 7 !important
}

.ext-row-start-auto {
    grid-row-start: auto !important
}

.ext-row-end-1 {
    grid-row-end: 1 !important
}

.ext-row-end-2 {
    grid-row-end: 2 !important
}

.ext-row-end-3 {
    grid-row-end: 3 !important
}

.ext-row-end-4 {
    grid-row-end: 4 !important
}

.ext-row-end-5 {
    grid-row-end: 5 !important
}

.ext-row-end-6 {
    grid-row-end: 6 !important
}

.ext-row-end-7 {
    grid-row-end: 7 !important
}

.ext-row-end-auto {
    grid-row-end: auto !important
}

.ext-m-0:not([style*=margin]) {
    margin: 0 !important
}

.ext-m-auto:not([style*=margin]) {
    margin: auto !important
}

.ext-m-base:not([style*=margin]) {
    // margin: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-m-lg:not([style*=margin]) {
    margin: var(ExtendifySpacingLarge) !important
}

.extM-base:not([style*=margin]) {
    // margin: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extM-lg:not([style*=margin]) {
    margin: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-mx-0:not([style*=margin]) {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.ext-mx-auto:not([style*=margin]) {
    margin-left: auto !important;
    margin-right: auto !important
}

.ext-mx-base:not([style*=margin]) {
    // margin-left: var($wpStyleBlock-gap, 1.75rem) !important;
    // margin-right: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-mx-lg:not([style*=margin]) {
    margin-left: var(ExtendifySpacingLarge) !important;
    margin-right: var(ExtendifySpacingLarge) !important
}

.extMx-base:not([style*=margin]) {
    margin-left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important;
    margin-right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extMx-lg:not([style*=margin]) {
    margin-left: calc(var(ExtendifySpacingLarge)*(-1)) !important;
    margin-right: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-my-0:not([style*=margin]) {
    margin-bottom: 0 !important;
    margin-top: 0 !important
}

.ext-my-auto:not([style*=margin]) {
    margin-bottom: auto !important;
    margin-top: auto !important
}

.ext-my-base:not([style*=margin]) {
    // margin-bottom: var($wpStyleBlock-gap, 1.75rem) !important;
    // margin-top: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-my-lg:not([style*=margin]) {
    margin-bottom: var(ExtendifySpacingLarge) !important;
    margin-top: var(ExtendifySpacingLarge) !important
}

.extMy-base:not([style*=margin]) {
    // margin-bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important;
    // margin-top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extMy-lg:not([style*=margin]) {
    margin-bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important;
    margin-top: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-mt-0:not([style*=margin]) {
    margin-top: 0 !important
}

.ext-mt-auto:not([style*=margin]) {
    margin-top: auto !important
}

.ext-mt-base:not([style*=margin]) {
    margin-top: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-mt-lg:not([style*=margin]) {
    margin-top: var(ExtendifySpacingLarge) !important
}

.extMt-base:not([style*=margin]) {
    margin-top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extMt-lg:not([style*=margin]) {
    margin-top: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-mr-0:not([style*=margin]) {
    margin-right: 0 !important
}

.ext-mr-auto:not([style*=margin]) {
    margin-right: auto !important
}

.ext-mr-base:not([style*=margin]) {
    margin-right: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-mr-lg:not([style*=margin]) {
    margin-right: var(ExtendifySpacingLarge) !important
}

.extMr-base:not([style*=margin]) {
    margin-right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extMr-lg:not([style*=margin]) {
    margin-right: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-mb-0:not([style*=margin]) {
    margin-bottom: 0 !important
}

.ext-mb-auto:not([style*=margin]) {
    margin-bottom: auto !important
}

.ext-mb-base:not([style*=margin]) {
    margin-bottom: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-mb-lg:not([style*=margin]) {
    margin-bottom: var(ExtendifySpacingLarge) !important
}

.extMb-base:not([style*=margin]) {
    margin-bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extMb-lg:not([style*=margin]) {
    margin-bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-ml-0:not([style*=margin]) {
    margin-left: 0 !important
}

.ext-ml-auto:not([style*=margin]) {
    margin-left: auto !important
}

.ext-ml-base:not([style*=margin]) {
    margin-left: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-ml-lg:not([style*=margin]) {
    margin-left: var(ExtendifySpacingLarge) !important
}

.extMl-base:not([style*=margin]) {
    margin-left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
}

.extMl-lg:not([style*=margin]) {
    margin-left: calc(var(ExtendifySpacingLarge)*(-1)) !important
}

.ext-block {
    display: block !important
}

.ext-inline-block {
    display: inline-block !important
}

.ext-inline {
    display: inline !important
}

.ext-flex {
    display: flex !important
}

.ext-inline-flex {
    display: inline-flex !important
}

.ext-grid {
    display: grid !important
}

.ext-inline-grid {
    display: inline-grid !important
}

.ext-hidden {
    display: none !important
}

.ext-w-auto {
    width: auto !important
}

.ext-w-full {
    width: 100% !important
}

.ext-max-w-full {
    max-width: 100% !important
}

.ext-flex-1 {
    flex: 1 1 0% !important
}

.ext-flex-auto {
    flex: 1 1 auto !important
}

.ext-flex-initial {
    flex: 0 1 auto !important
}

.ext-flex-none {
    flex: none !important
}

.ext-flex-shrink-0 {
    flex-shrink: 0 !important
}

.ext-flex-shrink {
    flex-shrink: 1 !important
}

.ext-flex-grow-0 {
    flex-grow: 0 !important
}

.ext-flex-grow {
    flex-grow: 1 !important
}

.ext-list-none {
    list-style-type: none !important
}

.ext-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important
}

.ext-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.ext-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
}

.ext-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
}

.ext-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important
}

.ext-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important
}

.ext-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important
}

.ext-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important
}

.ext-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important
}

.ext-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important
}

.ext-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important
}

.ext-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important
}

.ext-grid-cols-none {
    grid-template-columns: none !important
}

.ext-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important
}

.ext-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important
}

.ext-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important
}

.ext-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important
}

.ext-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important
}

.ext-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important
}

.ext-grid-rows-none {
    grid-template-rows: none !important
}

.ext-flex-row {
    flex-direction: row !important
}

.ext-flex-row-reverse {
    flex-direction: row-reverse !important
}

.ext-flex-col {
    flex-direction: column !important
}

.ext-flex-col-reverse {
    flex-direction: column-reverse !important
}

.ext-flex-wrap {
    flex-wrap: wrap !important
}

.ext-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.ext-flex-nowrap {
    flex-wrap: nowrap !important
}

.ext-items-start {
    align-items: flex-start !important
}

.ext-items-end {
    align-items: flex-end !important
}

.ext-items-center {
    align-items: center !important
}

.ext-items-baseline {
    align-items: baseline !important
}

.ext-items-stretch {
    align-items: stretch !important
}

.ext-justify-start {
    justify-content: flex-start !important
}

.ext-justify-end {
    justify-content: flex-end !important
}

.ext-justify-center {
    justify-content: center !important
}

.ext-justify-between {
    justify-content: space-between !important
}

.ext-justify-around {
    justify-content: space-around !important
}

.ext-justify-evenly {
    justify-content: space-evenly !important
}

.ext-justify-items-start {
    justify-items: start !important
}

.ext-justify-items-end {
    justify-items: end !important
}

.ext-justify-items-center {
    justify-items: center !important
}

.ext-justify-items-stretch {
    justify-items: stretch !important
}

.ext-gap-0 {
    gap: 0 !important
}

.ext-gap-base {
    gap: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-gap-lg {
    gap: var(ExtendifySpacingLarge) !important
}

.ext-gap-x-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important
}

.ext-gap-x-base {
    -moz-column-gap: var($wpStyleBlock-gap, 1.75rem) !important;
    column-gap: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-gap-x-lg {
    -moz-column-gap: var(ExtendifySpacingLarge) !important;
    column-gap: var(ExtendifySpacingLarge) !important
}

.ext-gap-y-0 {
    row-gap: 0 !important
}

.ext-gap-y-base {
    row-gap: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-gap-y-lg {
    row-gap: var(ExtendifySpacingLarge) !important
}

.ext-justify-self-auto {
    justify-self: auto !important
}

.ext-justify-self-start {
    justify-self: start !important
}

.ext-justify-self-end {
    justify-self: end !important
}

.ext-justify-self-center {
    justify-self: center !important
}

.ext-justify-self-stretch {
    justify-self: stretch !important
}

.ext-rounded-none {
    border-radius: 0 !important
}

.ext-rounded-full {
    border-radius: 9999px !important
}

.ext-rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.ext-rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important
}

.ext-rounded-r-none {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.ext-rounded-r-full {
    border-bottom-right-radius: 9999px !important;
    border-top-right-radius: 9999px !important
}

.ext-rounded-b-none {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.ext-rounded-b-full {
    border-bottom-left-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important
}

.ext-rounded-l-none {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important
}

.ext-rounded-l-full {
    border-bottom-left-radius: 9999px !important;
    border-top-left-radius: 9999px !important
}

.ext-rounded-tl-none {
    border-top-left-radius: 0 !important
}

.ext-rounded-tl-full {
    border-top-left-radius: 9999px !important
}

.ext-rounded-tr-none {
    border-top-right-radius: 0 !important
}

.ext-rounded-tr-full {
    border-top-right-radius: 9999px !important
}

.ext-rounded-br-none {
    border-bottom-right-radius: 0 !important
}

.ext-rounded-br-full {
    border-bottom-right-radius: 9999px !important
}

.ext-rounded-bl-none {
    border-bottom-left-radius: 0 !important
}

.ext-rounded-bl-full {
    border-bottom-left-radius: 9999px !important
}

.ext-border-0 {
    border-width: 0 !important
}

.ext-border-t-0 {
    border-top-width: 0 !important
}

.ext-border-r-0 {
    border-right-width: 0 !important
}

.ext-border-b-0 {
    border-bottom-width: 0 !important
}

.ext-border-l-0 {
    border-left-width: 0 !important
}

.ext-p-0:not([style*=padding]) {
    padding: 0 !important
}

.ext-p-base:not([style*=padding]) {
    padding: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-p-lg:not([style*=padding]) {
    padding: var(ExtendifySpacingLarge) !important
}

.ext-px-0:not([style*=padding]) {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.ext-px-base:not([style*=padding]) {
    padding-left: var($wpStyleBlock-gap, 1.75rem) !important;
    padding-right: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-px-lg:not([style*=padding]) {
    padding-left: var(ExtendifySpacingLarge) !important;
    padding-right: var(ExtendifySpacingLarge) !important
}

.ext-py-0:not([style*=padding]) {
    padding-bottom: 0 !important;
    padding-top: 0 !important
}

.ext-py-base:not([style*=padding]) {
    padding-bottom: var($wpStyleBlock-gap, 1.75rem) !important;
    padding-top: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-py-lg:not([style*=padding]) {
    padding-bottom: var(ExtendifySpacingLarge) !important;
    padding-top: var(ExtendifySpacingLarge) !important
}

.ext-pt-0:not([style*=padding]) {
    padding-top: 0 !important
}

.ext-pt-base:not([style*=padding]) {
    padding-top: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-pt-lg:not([style*=padding]) {
    padding-top: var(ExtendifySpacingLarge) !important
}

.ext-pr-0:not([style*=padding]) {
    padding-right: 0 !important
}

.ext-pr-base:not([style*=padding]) {
    padding-right: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-pr-lg:not([style*=padding]) {
    padding-right: var(ExtendifySpacingLarge) !important
}

.ext-pb-0:not([style*=padding]) {
    padding-bottom: 0 !important
}

.ext-pb-base:not([style*=padding]) {
    padding-bottom: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-pb-lg:not([style*=padding]) {
    padding-bottom: var(ExtendifySpacingLarge) !important
}

.ext-pl-0:not([style*=padding]) {
    padding-left: 0 !important
}

.ext-pl-base:not([style*=padding]) {
    padding-left: var($wpStyleBlock-gap, 1.75rem) !important
}

.ext-pl-lg:not([style*=padding]) {
    padding-left: var(ExtendifySpacingLarge) !important
}

.ext-text-left {
    text-align: left !important
}

.ext-text-center {
    text-align: center !important
}

.ext-text-right {
    text-align: right !important
}

.ext-leading-none {
    line-height: 1 !important
}

.ext-leading-tight {
    line-height: 1.25 !important
}

.ext-leading-snug {
    line-height: 1.375 !important
}

.ext-leading-normal {
    line-height: 1.5 !important
}

.ext-leading-relaxed {
    line-height: 1.625 !important
}

.ext-leading-loose {
    line-height: 2 !important
}

.clip-path--rhombus img {
    -webkit-clip-path: polygon(15% 6%, 80% 29%, 84% 93%, 23% 69%);
    clip-path: polygon(15% 6%, 80% 29%, 84% 93%, 23% 69%)
}

.clip-path--diamond img {
    -webkit-clip-path: polygon(5% 29%, 60% 2%, 91% 64%, 36% 89%);
    clip-path: polygon(5% 29%, 60% 2%, 91% 64%, 36% 89%)
}

.clip-path--rhombus-alt img {
    -webkit-clip-path: polygon(14% 9%, 85% 24%, 91% 89%, 19% 76%);
    clip-path: polygon(14% 9%, 85% 24%, 91% 89%, 19% 76%)
}

.wp-block-columns[class*=fullwidth-cols] {
    margin-bottom: unset
}

.wp-block-column.editor\:pointer-events-none {
    margin-bottom: 0 !important;
    margin-top: 0 !important
}

.is-root-container.block-editor-block-list__layout>[data-align=full]:not(:first-of-type)>.wp-block-column.editor\:pointer-events-none,
.is-root-container.block-editor-block-list__layout>[data-align=wide]>.wp-block-column.editor\:pointer-events-none {
    margin-top: calc(var($wpStyleBlock-gap, 28px)*(-1)) !important
}

.ext .wp-block-columns .wp-block-column[style*=padding] {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.ext .wp-block-columns+.wp-block-columns:not([class*=mt-]):not([class*=my-]):not([style*=margin]) {
    margin-top: 0 !important
}

[class*=fullwidth-cols] .wp-block-column:first-child,
[class*=fullwidth-cols] .wp-block-group:first-child {
    margin-top: 0
}

[class*=fullwidth-cols] .wp-block-column:last-child,
[class*=fullwidth-cols] .wp-block-group:last-child {
    margin-bottom: 0
}

[class*=fullwidth-cols] .wp-block-column:first-child>*,
[class*=fullwidth-cols] .wp-block-column>:first-child {
    margin-top: 0
}

.ext .is-not-stacked-on-mobile .wp-block-column,
[class*=fullwidth-cols] .wp-block-column>:last-child {
    margin-bottom: 0
}

.wp-block-columns[class*=fullwidth-cols]:not(.is-not-stacked-on-mobile)>.wp-block-column:not(:last-child) {
    margin-bottom: var($wpStyleBlock-gap, 1.75rem)
}

@media (min-width:782px) {
    .wp-block-columns[class*=fullwidth-cols]:not(.is-not-stacked-on-mobile)>.wp-block-column:not(:last-child) {
        margin-bottom: 0
    }
}

.wp-block-columns[class*=fullwidth-cols].is-not-stacked-on-mobile>.wp-block-column {
    margin-bottom: 0 !important
}

@media (min-width:600px) and (max-width:781px) {
    .wp-block-columns[class*=fullwidth-cols]:not(.is-not-stacked-on-mobile)>.wp-block-column:nth-child(2n) {
        margin-left: var($wpStyleBlock-gap, 2em)
    }
}

@media (max-width:781px) {
    .tablet\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile) {
        flex-wrap: wrap
    }

    .tablet\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column,
    .tablet\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column:not([style*=margin]) {
        margin-left: 0 !important
    }

    .tablet\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
        flex-basis: 100% !important
    }
}

@media (max-width:1079px) {
    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile) {
        flex-wrap: wrap
    }

    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column,
    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column:not([style*=margin]) {
        margin-left: 0 !important
    }

    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
        flex-basis: 100% !important
    }

    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column:not(:last-child) {
        margin-bottom: var($wpStyleBlock-gap, 1.75rem) !important
    }
}

.direction-rtl {
    direction: rtl
}

.direction-ltr {
    direction: ltr
}

.is-style-inline-list {
    padding-left: 0 !important
}

.is-style-inline-list li {
    list-style-type: none !important
}

@media (min-width:782px) {
    .is-style-inline-list li {
        display: inline !important;
        margin-right: var($wpStyleBlock-gap, 1.75rem) !important
    }
}

@media (min-width:782px) {
    .is-style-inline-list li:first-child {
        margin-left: 0 !important
    }
}

@media (min-width:782px) {
    .is-style-inline-list li:last-child {
        margin-right: 0 !important
    }
}

.bring-to-front {
    position: relative;
    z-index: 10
}

.text-stroke {
    -webkit-text-stroke-color: var($wpPresetColorBackground)
}

.text-stroke,
.text-strokePrimary {
    -webkit-text-stroke-width: var($wpCustom--typography--text-stroke-width, 2px)
}

.text-strokePrimary {
    -webkit-text-stroke-color: var($wpPresetColorPrimary)
}

.text-strokeSecondary {
    -webkit-text-stroke-width: var($wpCustom--typography--text-stroke-width, 2px);
    -webkit-text-stroke-color: var($wpPresetColorSecondary)
}

.editor\:no-caption .block-editor-rich-text__editable {
    display: none !important
}

.editor\:no-inserter .wp-block-column:not(.is-selected)>.block-list-appender,
.editor\:no-inserter .wp-block-cover__inner-container>.block-list-appender,
.editor\:no-inserter .wp-block-group__inner-container>.block-list-appender,
.editor\:no-inserter>.block-list-appender {
    display: none
}

.editor\:no-resize .components-resizable-box__handle,
.editor\:no-resize .components-resizable-box__handle:after,
.editor\:no-resize .components-resizable-box__side-handle:before {
    display: none;
    pointer-events: none
}

.editor\:no-resize .components-resizable-box__container {
    display: block
}

.editor\:pointer-events-none {
    pointer-events: none
}

.is-style-angled {
    justify-content: flex-end
}

.ext .is-style-angled>[class*=_inner-container],
.is-style-angled {
    align-items: center
}

.is-style-angled .wp-block-cover__image-background,
.is-style-angled .wp-block-cover__video-background {
    -webkit-clip-path: polygon(0 0, 30% 0, 50% 100%, 0 100%);
    clip-path: polygon(0 0, 30% 0, 50% 100%, 0 100%);
    z-index: 1
}

@media (min-width:782px) {

    .is-style-angled .wp-block-cover__image-background,
    .is-style-angled .wp-block-cover__video-background {
        -webkit-clip-path: polygon(0 0, 55% 0, 65% 100%, 0 100%);
        clip-path: polygon(0 0, 55% 0, 65% 100%, 0 100%)
    }
}

.has-foreground-color {
    color: var($wpPresetColorForeground, #000) !important
}

.has-foreground-background-color {
    background-color: var($wpPresetColorForeground, #000) !important
}

.has-background-color {
    color: var($wpPresetColorBackground, #fff) !important
}

.has-background-background-color {
    background-color: var($wpPresetColorBackground, #fff) !important
}

.has-primary-color {
    color: var($wpPresetColorPrimary, #4b5563) !important
}

.has-primary-background-color {
    background-color: var($wpPresetColorPrimary, #4b5563) !important
}

.has-secondary-color {
    color: var($wpPresetColorSecondary, #9ca3af) !important
}

.has-secondary-background-color {
    background-color: var($wpPresetColorSecondary, #9ca3af) !important
}

.ext.has-text-color h1,
.ext.has-text-color h2,
.ext.has-text-color h3,
.ext.has-text-color h4,
.ext.has-text-color h5,
.ext.has-text-color h6,
.ext.has-text-color p {
    color: currentColor
}

.has-white-color {
    color: var($wpPresetColorwhite, #fff) !important
}

.has-black-color {
    color: var($wpPresetColorBlack, #000) !important
}

.has-ext-foreground-background-color {
    background-color: var($wpPresetColorForeground, var($wpPresetColorBlack, #000)) !important
}

.has-ext-primary-background-color {
    background-color: var($wpPresetColorPrimary, var($wpPresetColorCyan-bluish-gray, #000)) !important
}

.wp-block-button__link.has-black-background-color {
    border-color: var($wpPresetColorBlack, #000)
}

.wp-block-button__link.has-white-background-color {
    border-color: var($wpPresetColorwhite, #fff)
}

.has-ext-small-font-size {
    font-size: var($wpPresetFont-sizeExt-small) !important
}

.has-ext-medium-font-size {
    font-size: var($wpPresetFont-sizeExt-medium) !important
}

.has-ext-large-font-size {
    font-size: var($wpPresetFont-sizeExt-large) !important;
    line-height: 1.2
}

.has-ext-x-large-font-size {
    font-size: var($wpPresetFont-sizeExt-x-large) !important;
    line-height: 1
}

.has-ext-xx-large-font-size {
    font-size: var($wpPresetFont-sizeExt-xx-large) !important;
    line-height: 1
}

.has-ext-x-large-font-size:not([style*=line-height]),
.has-ext-xx-large-font-size:not([style*=line-height]) {
    line-height: 1.1
}

.ext .wp-block-group>* {
    margin-bottom: 0;
    margin-top: 0
}

.ext .wp-block-group>*+* {
    margin-bottom: 0
}

.ext .wp-block-group>*+*,
.ext h2 {
    margin-top: var($wpStyleBlock-gap, 1.75rem)
}

.ext h2 {
    margin-bottom: var($wpStyleBlock-gap, 1.75rem)
}

.has-ext-x-large-font-size+h3,
.has-ext-x-large-font-size+p {
    margin-top: .5rem
}

.ext .wp-block-buttons>.wp-block-button.wp-block-button__width-25 {
    min-width: 12rem;
    width: calc(25% - var($wpStyleBlock-gap, .5em)*.75)
}

.ext .ext-grid>[class*=_inner-container] {
    display: grid
}

.ext>[class*=_inner-container]>.ext-grid:not([class*=columns]),
.ext>[class*=_inner-container]>.wp-block>.ext-grid:not([class*=columns]) {
    display: initial !important
}

.ext .ext-grid-cols-1>[class*=_inner-container] {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-2>[class*=_inner-container] {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-3>[class*=_inner-container] {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-4>[class*=_inner-container] {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-5>[class*=_inner-container] {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-6>[class*=_inner-container] {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-7>[class*=_inner-container] {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-8>[class*=_inner-container] {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-9>[class*=_inner-container] {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-10>[class*=_inner-container] {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-11>[class*=_inner-container] {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-12>[class*=_inner-container] {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-13>[class*=_inner-container] {
    grid-template-columns: repeat(13, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-none>[class*=_inner-container] {
    grid-template-columns: none !important
}

.ext .ext-grid-rows-1>[class*=_inner-container] {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-2>[class*=_inner-container] {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-3>[class*=_inner-container] {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-4>[class*=_inner-container] {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-5>[class*=_inner-container] {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-6>[class*=_inner-container] {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-none>[class*=_inner-container] {
    grid-template-rows: none !important
}

.ext .ext-items-start>[class*=_inner-container] {
    align-items: flex-start !important
}

.ext .ext-items-end>[class*=_inner-container] {
    align-items: flex-end !important
}

.ext .ext-items-center>[class*=_inner-container] {
    align-items: center !important
}

.ext .ext-items-baseline>[class*=_inner-container] {
    align-items: baseline !important
}

.ext .ext-items-stretch>[class*=_inner-container] {
    align-items: stretch !important
}

.ext.wp-block-group>:last-child {
    margin-bottom: 0
}

.ext .wp-block-group__inner-container {
    padding: 0 !important
}

.ext.has-background {
    padding-left: var($wpStyleBlock-gap, 1.75rem);
    padding-right: var($wpStyleBlock-gap, 1.75rem)
}

.ext [class*=inner-container]>.alignwide [class*=inner-container],
.ext [class*=inner-container]>[data-align=wide] [class*=inner-container] {
    max-width: var(--responsive--alignwide-width, 120rem)
}

.ext [class*=inner-container]>.alignwide [class*=inner-container]>*,
.ext [class*=inner-container]>[data-align=wide] [class*=inner-container]>* {
    max-width: 100% !important
}

.ext .wp-block-image {
    position: relative;
    text-align: center
}

.ext .wp-block-image img {
    display: inline-block;
    vertical-align: middle
}

body {
    ExtendifySpacingLarge: var($wpCustomSpacingLarge, clamp(2em, 8vw, 8em));
    $wpPresetFont-sizeExt-small: 1rem;
    $wpPresetFont-sizeExt-medium: 1.125rem;
    $wpPresetFont-sizeExt-large: clamp(1.65rem, 3.5vw, 2.15rem);
    $wpPresetFont-sizeExt-x-large: clamp(3rem, 6vw, 4.75rem);
    $wpPresetFont-sizeExt-xx-large: clamp(3.25rem, 7.5vw, 5.75rem);
    $wpPresetColorBlack: #000;
    $wpPresetColorwhite: #fff
}

.ext * {
    box-sizing: border-box
}

.block-editor-block-preview__content-iframe .ext [data-type="core/spacer"] .components-resizable-box__container {
    background: transparent !important
}

.block-editor-block-preview__content-iframe .ext [data-type="core/spacer"] .block-library-spacer__resize-container:before {
    display: none !important
}

.ext .wp-block-group__inner-container figure.wp-block-gallery.alignfull {
    margin-bottom: unset;
    margin-top: unset
}

.ext .alignwide {
    margin-left: auto !important;
    margin-right: auto !important
}

.is-root-container.block-editor-block-list__layout>[data-align=full]:not(:first-of-type)>.ext-my-0,
.is-root-container.block-editor-block-list__layout>[data-align=wide]>.ext-my-0:not([style*=margin]) {
    margin-top: calc(var($wpStyleBlock-gap, 28px)*(-1)) !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-50 {
    min-height: 50vw !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-60 {
    min-height: 60vw !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-70 {
    min-height: 70vw !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-80 {
    min-height: 80vw !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-100 {
    min-height: 100vw !important
}

.ext-mr-0.alignfull:not([style*=margin]):not([style*=margin]) {
    margin-right: 0 !important
}

.ext-ml-0:not([style*=margin]):not([style*=margin]) {
    margin-left: 0 !important
}

.is-root-container .wp-block[data-align=full]>.ext-mx-0:not([style*=margin]):not([style*=margin]) {
    margin-left: calc(var($wpCustomSpacing--outer, 0)*1) !important;
    margin-right: calc(var($wpCustomSpacing--outer, 0)*1) !important;
    overflow: hidden;
    width: unset
}

@media (min-width:782px) {
    .tablet\:ext-absolute {
        position: absolute !important
    }

    .tablet\:ext-relative {
        position: relative !important
    }

    .tablet\:ext-top-base {
        top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-top-lg {
        top: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext--top-base {
        top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:ext--top-lg {
        top: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-right-base {
        right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-right-lg {
        right: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext--right-base {
        right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:ext--right-lg {
        right: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-bottom-base {
        bottom: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-bottom-lg {
        bottom: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extBottom-base {
        bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extBottom-lg {
        bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-left-base {
        left: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-left-lg {
        left: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extLeft-base {
        left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extLeft-lg {
        left: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-order-1 {
        order: 1 !important
    }

    .tablet\:ext-order-2 {
        order: 2 !important
    }

    .tablet\:ext-m-0:not([style*=margin]) {
        margin: 0 !important
    }

    .tablet\:ext-m-auto:not([style*=margin]) {
        margin: auto !important
    }

    .tablet\:ext-m-base:not([style*=margin]) {
        margin: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-m-lg:not([style*=margin]) {
        margin: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extM-base:not([style*=margin]) {
        margin: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extM-lg:not([style*=margin]) {
        margin: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-mx-0:not([style*=margin]) {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .tablet\:ext-mx-auto:not([style*=margin]) {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .tablet\:ext-mx-base:not([style*=margin]) {
        margin-left: var($wpStyleBlock-gap, 1.75rem) !important;
        margin-right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-mx-lg:not([style*=margin]) {
        margin-left: var(ExtendifySpacingLarge) !important;
        margin-right: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extMx-base:not([style*=margin]) {
        margin-left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important;
        margin-right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extMx-lg:not([style*=margin]) {
        margin-left: calc(var(ExtendifySpacingLarge)*(-1)) !important;
        margin-right: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-my-0:not([style*=margin]) {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .tablet\:ext-my-auto:not([style*=margin]) {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .tablet\:ext-my-base:not([style*=margin]) {
        margin-bottom: var($wpStyleBlock-gap, 1.75rem) !important;
        margin-top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-my-lg:not([style*=margin]) {
        margin-bottom: var(ExtendifySpacingLarge) !important;
        margin-top: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extMy-base:not([style*=margin]) {
        margin-bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important;
        margin-top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extMy-lg:not([style*=margin]) {
        margin-bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important;
        margin-top: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-mt-0:not([style*=margin]) {
        margin-top: 0 !important
    }

    .tablet\:ext-mt-auto:not([style*=margin]) {
        margin-top: auto !important
    }

    .tablet\:ext-mt-base:not([style*=margin]) {
        margin-top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-mt-lg:not([style*=margin]) {
        margin-top: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extMt-base:not([style*=margin]) {
        margin-top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extMt-lg:not([style*=margin]) {
        margin-top: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-mr-0:not([style*=margin]) {
        margin-right: 0 !important
    }

    .tablet\:ext-mr-auto:not([style*=margin]) {
        margin-right: auto !important
    }

    .tablet\:ext-mr-base:not([style*=margin]) {
        margin-right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-mr-lg:not([style*=margin]) {
        margin-right: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extMr-base:not([style*=margin]) {
        margin-right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extMr-lg:not([style*=margin]) {
        margin-right: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-mb-0:not([style*=margin]) {
        margin-bottom: 0 !important
    }

    .tablet\:ext-mb-auto:not([style*=margin]) {
        margin-bottom: auto !important
    }

    .tablet\:ext-mb-base:not([style*=margin]) {
        margin-bottom: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-mb-lg:not([style*=margin]) {
        margin-bottom: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extMb-base:not([style*=margin]) {
        margin-bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extMb-lg:not([style*=margin]) {
        margin-bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-ml-0:not([style*=margin]) {
        margin-left: 0 !important
    }

    .tablet\:ext-ml-auto:not([style*=margin]) {
        margin-left: auto !important
    }

    .tablet\:ext-ml-base:not([style*=margin]) {
        margin-left: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-ml-lg:not([style*=margin]) {
        margin-left: var(ExtendifySpacingLarge) !important
    }

    .tablet\:extMl-base:not([style*=margin]) {
        margin-left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .tablet\:extMl-lg:not([style*=margin]) {
        margin-left: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .tablet\:ext-block {
        display: block !important
    }

    .tablet\:ext-inline-block {
        display: inline-block !important
    }

    .tablet\:ext-inline {
        display: inline !important
    }

    .tablet\:ext-flex {
        display: flex !important
    }

    .tablet\:ext-inline-flex {
        display: inline-flex !important
    }

    .tablet\:ext-grid {
        display: grid !important
    }

    .tablet\:ext-inline-grid {
        display: inline-grid !important
    }

    .tablet\:ext-hidden {
        display: none !important
    }

    .tablet\:ext-w-auto {
        width: auto !important
    }

    .tablet\:ext-w-full {
        width: 100% !important
    }

    .tablet\:ext-max-w-full {
        max-width: 100% !important
    }

    .tablet\:ext-flex-1 {
        flex: 1 1 0% !important
    }

    .tablet\:ext-flex-auto {
        flex: 1 1 auto !important
    }

    .tablet\:ext-flex-initial {
        flex: 0 1 auto !important
    }

    .tablet\:ext-flex-none {
        flex: none !important
    }

    .tablet\:ext-flex-shrink-0 {
        flex-shrink: 0 !important
    }

    .tablet\:ext-flex-shrink {
        flex-shrink: 1 !important
    }

    .tablet\:ext-flex-grow-0 {
        flex-grow: 0 !important
    }

    .tablet\:ext-flex-grow {
        flex-grow: 1 !important
    }

    .tablet\:ext-list-none {
        list-style-type: none !important
    }

    .tablet\:ext-grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-none {
        grid-template-columns: none !important
    }

    .tablet\:ext-flex-row {
        flex-direction: row !important
    }

    .tablet\:ext-flex-row-reverse {
        flex-direction: row-reverse !important
    }

    .tablet\:ext-flex-col {
        flex-direction: column !important
    }

    .tablet\:ext-flex-col-reverse {
        flex-direction: column-reverse !important
    }

    .tablet\:ext-flex-wrap {
        flex-wrap: wrap !important
    }

    .tablet\:ext-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .tablet\:ext-flex-nowrap {
        flex-wrap: nowrap !important
    }

    .tablet\:ext-items-start {
        align-items: flex-start !important
    }

    .tablet\:ext-items-end {
        align-items: flex-end !important
    }

    .tablet\:ext-items-center {
        align-items: center !important
    }

    .tablet\:ext-items-baseline {
        align-items: baseline !important
    }

    .tablet\:ext-items-stretch {
        align-items: stretch !important
    }

    .tablet\:ext-justify-start {
        justify-content: flex-start !important
    }

    .tablet\:ext-justify-end {
        justify-content: flex-end !important
    }

    .tablet\:ext-justify-center {
        justify-content: center !important
    }

    .tablet\:ext-justify-between {
        justify-content: space-between !important
    }

    .tablet\:ext-justify-around {
        justify-content: space-around !important
    }

    .tablet\:ext-justify-evenly {
        justify-content: space-evenly !important
    }

    .tablet\:ext-justify-items-start {
        justify-items: start !important
    }

    .tablet\:ext-justify-items-end {
        justify-items: end !important
    }

    .tablet\:ext-justify-items-center {
        justify-items: center !important
    }

    .tablet\:ext-justify-items-stretch {
        justify-items: stretch !important
    }

    .tablet\:ext-justify-self-auto {
        justify-self: auto !important
    }

    .tablet\:ext-justify-self-start {
        justify-self: start !important
    }

    .tablet\:ext-justify-self-end {
        justify-self: end !important
    }

    .tablet\:ext-justify-self-center {
        justify-self: center !important
    }

    .tablet\:ext-justify-self-stretch {
        justify-self: stretch !important
    }

    .tablet\:ext-p-0:not([style*=padding]) {
        padding: 0 !important
    }

    .tablet\:ext-p-base:not([style*=padding]) {
        padding: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-p-lg:not([style*=padding]) {
        padding: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext-px-0:not([style*=padding]) {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .tablet\:ext-px-base:not([style*=padding]) {
        padding-left: var($wpStyleBlock-gap, 1.75rem) !important;
        padding-right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-px-lg:not([style*=padding]) {
        padding-left: var(ExtendifySpacingLarge) !important;
        padding-right: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext-py-0:not([style*=padding]) {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .tablet\:ext-py-base:not([style*=padding]) {
        padding-bottom: var($wpStyleBlock-gap, 1.75rem) !important;
        padding-top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-py-lg:not([style*=padding]) {
        padding-bottom: var(ExtendifySpacingLarge) !important;
        padding-top: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext-pt-0:not([style*=padding]) {
        padding-top: 0 !important
    }

    .tablet\:ext-pt-base:not([style*=padding]) {
        padding-top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-pt-lg:not([style*=padding]) {
        padding-top: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext-pr-0:not([style*=padding]) {
        padding-right: 0 !important
    }

    .tablet\:ext-pr-base:not([style*=padding]) {
        padding-right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-pr-lg:not([style*=padding]) {
        padding-right: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext-pb-0:not([style*=padding]) {
        padding-bottom: 0 !important
    }

    .tablet\:ext-pb-base:not([style*=padding]) {
        padding-bottom: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-pb-lg:not([style*=padding]) {
        padding-bottom: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext-pl-0:not([style*=padding]) {
        padding-left: 0 !important
    }

    .tablet\:ext-pl-base:not([style*=padding]) {
        padding-left: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .tablet\:ext-pl-lg:not([style*=padding]) {
        padding-left: var(ExtendifySpacingLarge) !important
    }

    .tablet\:ext-text-left {
        text-align: left !important
    }

    .tablet\:ext-text-center {
        text-align: center !important
    }

    .tablet\:ext-text-right {
        text-align: right !important
    }
}

@media (min-width:1080px) {
    .desktop\:ext-absolute {
        position: absolute !important
    }

    .desktop\:ext-relative {
        position: relative !important
    }

    .desktop\:ext-top-base {
        top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-top-lg {
        top: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext--top-base {
        top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:ext--top-lg {
        top: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-right-base {
        right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-right-lg {
        right: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext--right-base {
        right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:ext--right-lg {
        right: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-bottom-base {
        bottom: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-bottom-lg {
        bottom: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extBottom-base {
        bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extBottom-lg {
        bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-left-base {
        left: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-left-lg {
        left: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extLeft-base {
        left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extLeft-lg {
        left: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-order-1 {
        order: 1 !important
    }

    .desktop\:ext-order-2 {
        order: 2 !important
    }

    .desktop\:ext-m-0:not([style*=margin]) {
        margin: 0 !important
    }

    .desktop\:ext-m-auto:not([style*=margin]) {
        margin: auto !important
    }

    .desktop\:ext-m-base:not([style*=margin]) {
        margin: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-m-lg:not([style*=margin]) {
        margin: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extM-base:not([style*=margin]) {
        margin: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extM-lg:not([style*=margin]) {
        margin: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-mx-0:not([style*=margin]) {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .desktop\:ext-mx-auto:not([style*=margin]) {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .desktop\:ext-mx-base:not([style*=margin]) {
        margin-left: var($wpStyleBlock-gap, 1.75rem) !important;
        margin-right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-mx-lg:not([style*=margin]) {
        margin-left: var(ExtendifySpacingLarge) !important;
        margin-right: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extMx-base:not([style*=margin]) {
        margin-left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important;
        margin-right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extMx-lg:not([style*=margin]) {
        margin-left: calc(var(ExtendifySpacingLarge)*(-1)) !important;
        margin-right: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-my-0:not([style*=margin]) {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .desktop\:ext-my-auto:not([style*=margin]) {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .desktop\:ext-my-base:not([style*=margin]) {
        margin-bottom: var($wpStyleBlock-gap, 1.75rem) !important;
        margin-top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-my-lg:not([style*=margin]) {
        margin-bottom: var(ExtendifySpacingLarge) !important;
        margin-top: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extMy-base:not([style*=margin]) {
        margin-bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important;
        margin-top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extMy-lg:not([style*=margin]) {
        margin-bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important;
        margin-top: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-mt-0:not([style*=margin]) {
        margin-top: 0 !important
    }

    .desktop\:ext-mt-auto:not([style*=margin]) {
        margin-top: auto !important
    }

    .desktop\:ext-mt-base:not([style*=margin]) {
        margin-top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-mt-lg:not([style*=margin]) {
        margin-top: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extMt-base:not([style*=margin]) {
        margin-top: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extMt-lg:not([style*=margin]) {
        margin-top: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-mr-0:not([style*=margin]) {
        margin-right: 0 !important
    }

    .desktop\:ext-mr-auto:not([style*=margin]) {
        margin-right: auto !important
    }

    .desktop\:ext-mr-base:not([style*=margin]) {
        margin-right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-mr-lg:not([style*=margin]) {
        margin-right: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extMr-base:not([style*=margin]) {
        margin-right: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extMr-lg:not([style*=margin]) {
        margin-right: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-mb-0:not([style*=margin]) {
        margin-bottom: 0 !important
    }

    .desktop\:ext-mb-auto:not([style*=margin]) {
        margin-bottom: auto !important
    }

    .desktop\:ext-mb-base:not([style*=margin]) {
        margin-bottom: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-mb-lg:not([style*=margin]) {
        margin-bottom: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extMb-base:not([style*=margin]) {
        margin-bottom: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extMb-lg:not([style*=margin]) {
        margin-bottom: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-ml-0:not([style*=margin]) {
        margin-left: 0 !important
    }

    .desktop\:ext-ml-auto:not([style*=margin]) {
        margin-left: auto !important
    }

    .desktop\:ext-ml-base:not([style*=margin]) {
        margin-left: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-ml-lg:not([style*=margin]) {
        margin-left: var(ExtendifySpacingLarge) !important
    }

    .desktop\:extMl-base:not([style*=margin]) {
        margin-left: calc(var($wpStyleBlock-gap, 1.75rem)*(-1)) !important
    }

    .desktop\:extMl-lg:not([style*=margin]) {
        margin-left: calc(var(ExtendifySpacingLarge)*(-1)) !important
    }

    .desktop\:ext-block {
        display: block !important
    }

    .desktop\:ext-inline-block {
        display: inline-block !important
    }

    .desktop\:ext-inline {
        display: inline !important
    }

    .desktop\:ext-flex {
        display: flex !important
    }

    .desktop\:ext-inline-flex {
        display: inline-flex !important
    }

    .desktop\:ext-grid {
        display: grid !important
    }

    .desktop\:ext-inline-grid {
        display: inline-grid !important
    }

    .desktop\:ext-hidden {
        display: none !important
    }

    .desktop\:ext-w-auto {
        width: auto !important
    }

    .desktop\:ext-w-full {
        width: 100% !important
    }

    .desktop\:ext-max-w-full {
        max-width: 100% !important
    }

    .desktop\:ext-flex-1 {
        flex: 1 1 0% !important
    }

    .desktop\:ext-flex-auto {
        flex: 1 1 auto !important
    }

    .desktop\:ext-flex-initial {
        flex: 0 1 auto !important
    }

    .desktop\:ext-flex-none {
        flex: none !important
    }

    .desktop\:ext-flex-shrink-0 {
        flex-shrink: 0 !important
    }

    .desktop\:ext-flex-shrink {
        flex-shrink: 1 !important
    }

    .desktop\:ext-flex-grow-0 {
        flex-grow: 0 !important
    }

    .desktop\:ext-flex-grow {
        flex-grow: 1 !important
    }

    .desktop\:ext-list-none {
        list-style-type: none !important
    }

    .desktop\:ext-grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-none {
        grid-template-columns: none !important
    }

    .desktop\:ext-flex-row {
        flex-direction: row !important
    }

    .desktop\:ext-flex-row-reverse {
        flex-direction: row-reverse !important
    }

    .desktop\:ext-flex-col {
        flex-direction: column !important
    }

    .desktop\:ext-flex-col-reverse {
        flex-direction: column-reverse !important
    }

    .desktop\:ext-flex-wrap {
        flex-wrap: wrap !important
    }

    .desktop\:ext-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .desktop\:ext-flex-nowrap {
        flex-wrap: nowrap !important
    }

    .desktop\:ext-items-start {
        align-items: flex-start !important
    }

    .desktop\:ext-items-end {
        align-items: flex-end !important
    }

    .desktop\:ext-items-center {
        align-items: center !important
    }

    .desktop\:ext-items-baseline {
        align-items: baseline !important
    }

    .desktop\:ext-items-stretch {
        align-items: stretch !important
    }

    .desktop\:ext-justify-start {
        justify-content: flex-start !important
    }

    .desktop\:ext-justify-end {
        justify-content: flex-end !important
    }

    .desktop\:ext-justify-center {
        justify-content: center !important
    }

    .desktop\:ext-justify-between {
        justify-content: space-between !important
    }

    .desktop\:ext-justify-around {
        justify-content: space-around !important
    }

    .desktop\:ext-justify-evenly {
        justify-content: space-evenly !important
    }

    .desktop\:ext-justify-items-start {
        justify-items: start !important
    }

    .desktop\:ext-justify-items-end {
        justify-items: end !important
    }

    .desktop\:ext-justify-items-center {
        justify-items: center !important
    }

    .desktop\:ext-justify-items-stretch {
        justify-items: stretch !important
    }

    .desktop\:ext-justify-self-auto {
        justify-self: auto !important
    }

    .desktop\:ext-justify-self-start {
        justify-self: start !important
    }

    .desktop\:ext-justify-self-end {
        justify-self: end !important
    }

    .desktop\:ext-justify-self-center {
        justify-self: center !important
    }

    .desktop\:ext-justify-self-stretch {
        justify-self: stretch !important
    }

    .desktop\:ext-p-0:not([style*=padding]) {
        padding: 0 !important
    }

    .desktop\:ext-p-base:not([style*=padding]) {
        padding: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-p-lg:not([style*=padding]) {
        padding: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext-px-0:not([style*=padding]) {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .desktop\:ext-px-base:not([style*=padding]) {
        padding-left: var($wpStyleBlock-gap, 1.75rem) !important;
        padding-right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-px-lg:not([style*=padding]) {
        padding-left: var(ExtendifySpacingLarge) !important;
        padding-right: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext-py-0:not([style*=padding]) {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .desktop\:ext-py-base:not([style*=padding]) {
        padding-bottom: var($wpStyleBlock-gap, 1.75rem) !important;
        padding-top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-py-lg:not([style*=padding]) {
        padding-bottom: var(ExtendifySpacingLarge) !important;
        padding-top: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext-pt-0:not([style*=padding]) {
        padding-top: 0 !important
    }

    .desktop\:ext-pt-base:not([style*=padding]) {
        padding-top: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-pt-lg:not([style*=padding]) {
        padding-top: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext-pr-0:not([style*=padding]) {
        padding-right: 0 !important
    }

    .desktop\:ext-pr-base:not([style*=padding]) {
        padding-right: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-pr-lg:not([style*=padding]) {
        padding-right: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext-pb-0:not([style*=padding]) {
        padding-bottom: 0 !important
    }

    .desktop\:ext-pb-base:not([style*=padding]) {
        padding-bottom: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-pb-lg:not([style*=padding]) {
        padding-bottom: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext-pl-0:not([style*=padding]) {
        padding-left: 0 !important
    }

    .desktop\:ext-pl-base:not([style*=padding]) {
        padding-left: var($wpStyleBlock-gap, 1.75rem) !important
    }

    .desktop\:ext-pl-lg:not([style*=padding]) {
        padding-left: var(ExtendifySpacingLarge) !important
    }

    .desktop\:ext-text-left {
        text-align: left !important
    }

    .desktop\:ext-text-center {
        text-align: center !important
    }

    .desktop\:ext-text-right {
        text-align: right !important
    }
}





.menu-item-2804>ul.sub-menu:after {
    background-image: url(https://agrofood-be87.kxcdn.com/agrofood/wp-content/uploads/2022/01/mega-shop-vegetables-2.jpg);
    background-repeat: no-repeat;
    background-position: right top;
}



/*
		I. CUSTOM FONT FAMILY
		II. CUSTOM FONT SIZE
		III. CUSTOM COLOR
	*/
header .logo img,
header .logo-header img {
    width: 190px;
}

@media only screen and (max-width: 1279px) {

    header .logo img,
    header .logo-header img {
        width: 160px;
    }
}

/*--------------------------------------------------------
		I. CUSTOM FONT FAMILY
	---------------------------------------------------------*/
html,
body,
label,
input,
textarea,
keygen,
select,
button,
body .font-body,
.ts-header nav.main-menu>ul.menu>li.font-body>a,
.ts-header nav.main-menu>ul>li.font-body>a,
.price del,
.product-price del,
.woocommerce div.product p.price del,
.woocommerce div.product span.price del,
.woocommerce div.product .yith-wfbt-section .yith-wfbt-form .price del,
ul.product_list_widget li .price del,
.woocommerce ul.product_list_widget li .price del,
.woocommerce .widget_shopping_cart .cart_list li .price del,
.woocommerce.widget_shopping_cart .cart_list li .price del,
.ts-tiny-cart-wrapper .dropdown-container ul.cart_list li .price,
.category-name .count,
.portfolio-info .cat-links a,
.mobile-menu-wrapper .mobile-menu .product,
.ts-header .menu-wrapper .vertical-menu-wrapper .product,
.ts-header .menu-wrapper .vertical-menu-wrapper .product,
.ts-header .menu-wrapper .vertical-menu-wrapper .product,
.ts-header .menu-wrapper .ts-menu .product,
.single-portfolio .meta-content .portfolio-info>span:last-child,
.single-portfolio .meta-content .portfolio-info>a:last-child,
.woocommerce div.product form.cart .variations label,
.ts-testimonial-wrapper blockquote .author-role,
.style-tabs-default .column-tabs ul.tabs li,
.style-tabs-vertical .column-tabs ul.tabs li,
.ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li,
body table.compare-list,
.ts-testimonial-wrapper.style-default blockquote,
.ts-list-of-product-categories-wrapper.style-horizontal h3.heading-title,
.ts-blogs article .entry-title,
.list-posts article .entry-title,
.button-text:not(.ts-banner):not(.elementor-widget-button),
.elementor-widget-button.button-text .elementor-button,
.ts-search-result-container .view-all-wrapper a,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce-page .woocommerce-error .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-message .button {
    font-family: $font-Helvetica;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2px;
}

.product-style-3 .brand-info-wrapper h3,
.product-per-page-form ul.perpage .perpage-current>span:last-child,
.woocommerce .woocommerce-ordering .orderby-current,
.woocommerce .woocommerce-ordering .orderby li a.current,
.product-per-page-form ul.perpage ul li a.current,
.product-filter-by-brand-wrapper select,
.product-filter-by-brand-wrapper select option[selected="selected"],
body #ts-filter-widget-area .select2-container--default .select2-selectionSingle .select2-selection__rendered .select2-selection__placeholder,
.woocommerce-page.archive #left-sidebar .select2-container--default .select2-selectionSingle .select2-selection__rendered .select2-selection__placeholder,
.woocommerce-page.archive #right-sidebar .select2-container--default .select2-selectionSingle .select2-selection__rendered .select2-selection__placeholder,
#tab-description .ul-style.list-inline li,
.ts-active-filters .widget_layered_nav_filters ul li a,
.woocommerce-widget-layered-nav ul.woocommerce-widget-layered-nav-list>li>a,
.woocommerce-widget-layered-nav ul li.chosen>a,
.ts-product-categories-widget-wrapper ul.product-categories>li>a,
.ts-product-categories-widget-wrapper ul li.current>a,
.widget_product_categories ul.product-categories>li>a,
.widget_product_categories ul li.current-cat>a,
.ts-product-attribute>div.option:not(.color)>a {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 500;
}

strong,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
table thead th,
table th,
.woocommerce table.shop_table th,
.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot th,
body .wp-block-calendar table th,
body blockquote,
.ts-store-notice,
.ts-tiny-cart-wrapper .total>span.total-title,
.widget_shopping_cart .total-title,
.woocommerce .widget_shopping_cart .total strong,
.woocommerce.widget_shopping_cart .total strong,
.elementor-widget-wp-widget-woocommerce_widget_cart .total strong,
.shopping-cart-wrapper .cart-control .cart-total,
.my-account-wrapper .ts-style-text .account-control>a,
.ts-testimonial-wrapper.style-default blockquote strong,
#group-icon-header .tab-mobile-menu li,
.ts-blogs article .entry-title,
.list-posts article .entry-title,
.ts-store-notice a,
div.product .summary .tags-link a,
.hightlight,
.category-name .heading-title,
.column-tabs ul.tabs li,
.entry-author .author a,
.comments-area .add-comment .comments-count,
.meta-bottom-2 .single-navigation,
ul.blog-filter-bar li.current,
.counter-wrapper>div,
.ts-portfolio-wrapper .filter-bar li.current,
.cart-collaterals .cart_totals>h2,
.cart_list .subtotal,
.ts-tiny-cart-wrapper .total,
.widget_shopping_cart .total-title,
.price,
.product-price,
.woocommerce div.product p.price,
.woocommerce div.product span.price,
ul.product_list_widget li .price,
.woocommerce ul.product_list_widget li .price,
.woocommerce .widget_shopping_cart .cart_list li .price,
.woocommerce.widget_shopping_cart .cart_list li .price,
.yith-wfbt-section .total_price,
.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total,
.elementor-widget-wp-widget-woocommerce_widget_cart .total,
.woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td.product-subtotal,
body .wishlist_table.images_grid li .item-details table.item-details-table td.label,
body .wishlist_table.mobile li .item-details table.item-details-table td.label,
body .wishlist_table.mobile li table.additional-info td.label,
body .wishlist_table.modern_grid li .item-details table.item-details-table td.label,
.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong,
.ts-list-of-product-categories-wrapper.style-horizontal>.list-categories ul li,
#main-content .woocommerce.columns-1>.products .product .product-name,
#main-content .woocommerce.columns-1>.products .product .meta-wrapper-2 .price,
.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .yith-wfbt-section .yith-wfbt-form .price,
.woocommerce div.product .yith-wfbt-section .yith-wfbt-form .price ins,
.woocommerce div.product form.cart div.quantity .screen-reader-text,
.woocommerce #reviews .comment-reply-title,
.woocommerce-tabs #reviews>.woocommerce-product-rating .review-count,
div.product .single-navigation>a>span,
.woocommerce-orders-table__cell-order-number,
.woocommerce-account .woocommerce-MyAccount-navigation li a,
html body>h1,
.more-less-buttons a,
.button,
a.button,
button,
.ts-button,
input[type^="submit"],
.shopping-cart p.buttons a,
a.wp-block-button__link,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce-page a.button,
.woocommerce-page button.button,
.woocommerce-page input.button,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce-page a.button.alt,
.woocommerce-page button.button.alt,
.woocommerce-page input.button.alt,
.woocommerce #respond input#submit,
.yith-woocompare-widget a.clear-all,
.yith-woocompare-widget a.compare,
.elementor-button-wrapper .elementor-button,
.elementor-widget-wp-widget-yith-woocompare-widget a.clear-all,
.elementor-widget-wp-widget-yith-woocompare-widget a.compare,
div.product .summary .meta-content>div>span:first-child,
div.product .summary .detail-meta-top>div>span:first-child,
.woocommerce div.product .woocommerce-tabs ul.tabs li,
div.button a,
input[type="submit"].dokan-btn,
a.dokan-btn,
.dokan-btn,
.wishlist_table .product-add-to-cart a,
body .woocommerce table.compare-list .add-to-cart td a,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce-page .woocommerce-error .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-message .button,
.woocommerce .product .category-name .count,
.ts-product-brand-wrapper .item .count,
.woocommerce .cart-collaterals .amount,
.woocommerce-shipping-fields h3#ship-to-different-address,
.ts-product-video-button,
.ts-product-360-button,
.portfolio-info>span:first-child,
.woocommerce>form>fieldset legend,
#ts-search-result-container .view-all-wrapper,
.woocommerce form table.shop_table tbody th,
.woocommerce form table.shop_table tfoot td,
.woocommerce form table.shop_table tfoot th,
.woocommerce table.shop_table ul#shipping_method .amount,
.column-tabs ul.tabs li.current,
.ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li.current,
.product-group-button .button-tooltip,
.ts-product-attribute .button-tooltip,
.availability .availability-text,
.view-all-wrapper a,
.ts-shortcode a.view-more,
.ts-portfolio-wrapper .item-wrapper a.like,
.portfolio-info .portfolio-like,
.tags-link a,
.wp-block-tag-cloud a,
.tagcloud a,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items li span.checkboxbutton.checked,
.widget-container.product-filter-by-brand ul>li.selected label,
.product-filter-by-availability ul li input[checked="checked"]+label,
.product-filter-by-price ul li.chosen label,
.woocommerce .widget_rating_filter ul li.chosen a,
.product-filter-by-color ul li.chosen a,
.product_list_widget .product-label>span.onsale,
.product_list_widget .product-label>span,
.woocommerce .product-label>span.onsale,
.woocommerce .product-label>span,
.dropdown-container .theme-title span,
.my-wishlist-wrapper .tini-wishlist .count-number,
.shopping-cart-wrapper .cart-control .cart-number,
.ts-pagination ul li a,
.ts-pagination ul li span,
.pagination-wrap ul.pagination>li>a,
.pagination-wrap ul.pagination>li>span,
.dokan-pagination-container .dokan-pagination li a,
.dokan-pagination-container .dokan-pagination li span,
.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span,
.pagination-wrap ul.pagination>li>a.prev,
.pagination-wrap ul.pagination>li>a.next,
.dokan-pagination-container .dokan-pagination li:first-child a,
.dokan-pagination-container .dokan-pagination li:last-child a,
.woocommerce nav.woocommerce-pagination ul li a.next,
.woocommerce nav.woocommerce-pagination ul li a.prev,
.ts-pagination ul li a.prev,
.ts-pagination ul li a.next {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
}

.ts-banner .box-content .banner-text strong {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 900;
}

.mobile-menu-wrapper .mobile-menu,
.ts-header .menu-wrapper .vertical-menu-wrapper,
.ts-header .normal-menu nav.vertical-menu ul.sub-menu>li,
.ts-header .menu-wrapper .ts-menu {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
}

.mobile-menu-wrapper .mobile-menu ul.sub-menu,
.ts-header .menu-wrapper .vertical-menu-wrapper ul.sub-menu,
.ts-header .menu-wrapper .ts-menu ul.sub-menu {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
}

.widget_nav_menu li[class*="agrofood-"],
.widget_nav_menu li[class*="fa-"],
.mobile-menu-wrapper .mobile-menu li[class*="agrofood-"],
.ts-header nav.main-menu li[class*="agrofood-"],
.mobile-menu-wrapper .mobile-menu li[class*="fa-"],
.ts-header nav.main-menu li[class*="fa-"] {
    font-family: Helvetica !important;
}

.product-name,
h3.product-name,
.product-name h3 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 500;
}

.yith-wfbt-item .product-name,
.ts-tiny-cart-wrapper .cart_list li .product-name,
.woocommerce .ts-tiny-cart-wrapper .product-name,
.woocommerce table.shop_table td.product-name {
    font-style: normal;
    font-weight: 400;
}

/*--------------------------------------------------------
		II. CUSTOM FONT SIZE
	---------------------------------------------------------*/
html,
body,
html body>h1,
.ts-testimonial-wrapper.style-default blockquote strong,
.ts-sidebar .widget_text small+a,
.woocommerce-shipping-fields h3#ship-to-different-address,
.ts-blogs-widget-wrapper .post-title,
.list-posts .entry-content .button-readmore,
.ts-blogs .entry-content .button-readmore,
.yith-wfbt-form .yith-wfbt-submit-block .button,
.commentlist li.comment .comment-actions strong {
    font-size: 14px;
}

.list-posts,
.ts-blogs,
.single-post>.entry-content>.content-wrapper {
    font-size: 15px;
}

.ts-store-notice,
.footer-container,
.entry-meta-middle,
.entry-meta-bottom,
.elementor-text-editor .boxed,
.shopping-cart-wrapper .cart-control .cart-total,
.ts-header nav.main-menu>ul.menu>li.font-body>a,
.ts-header nav.main-menu>ul>li.font-body>a,
.mobile-menu-wrapper nav ul li.font-body>a,
.ts-testimonial-wrapper.style-default blockquote,
.style-tabs-default .column-tabs ul.tabs li,
.style-tabs-vertical .column-tabs ul.tabs li,
.woocommerce ul.cart_list li .price,
.woocommerce ul.product_list_widget li .price,
.woocommerce>form.checkout #order_review,
#group-icon-header .tab-mobile-menu li,
.wishlist_table.mobile,
ul.blog-filter-bar li,
.woocommerce-cart article .woocommerce .cart-collaterals,
.ts-mailchimp-subscription-shortcode .mc4wp-form .subscribe-email .terms-conditions label,
#group-icon-header .tab-mobile-menu li,
.woocommerce-checkout #order_review,
.woocommerce #reviews #comments ol.commentlist li .comment-text .description {
    font-size: 13px;
}

.header-language,
.header-currency,
.ts-language-switcher,
.ts-currency-switcher,
.comment-meta .author,
.commentlist li.comment .comment-actions,
.comments-area .add-comment,
.meta-bottom-2 .single-navigation,
.product-group-button .button-tooltip,
.ts-product-attribute .button-tooltip,
body table.compare-list,
.ts-product.style-grid.has-shop-more-button .shop-more .button-text:not(.ts-banner):not(.elementor-widget-button),
.woocommerce>.woocommerce-order .woocommerce-customer-details,
.woocommerce .woocommerce-MyAccount-content .woocommerce-customer-details,
.ts-testimonial-wrapper blockquote .author-role .author,
.woocommerce ul.cart_list li .product-categories,
.woocommerce ul.cart_list li .product-brands,
.woocommerce ul.product_list_widget li .product-categories,
.woocommerce ul.product_list_widget li .product-brands,
.ts-active-filters,
.woocommerce #reviews>.woocommerce-product-rating,
.woocommerce .before-loop-wrapper,
.brand-info-wrapper,
.product-style-3 .brand-info-wrapper h3,
#ts-quickshop-modal .woocommerce-product-details__short-description,
#ts-filter-widget-area .ts-sidebar-content,
.ts-search-result-container .view-all-wrapper a,
#left-sidebar,
#right-sidebar {
    font-size: 13px;
}

.column-tabs .shop-more .button-text,
.ts-product-brand-info .show-all a,
.woocommerce .widget_price_filter .price_slider_amount .price_label>span,
.woocommerce .widget_price_filter .price_slider_amount .button {
    font-size: 13px !important;
}

small,
.font-small,
.font-small li,
.breadcrumb-title-wrapper .breadcrumbs,
.header-top,
.comment-meta,
#comment-wrapper .heading-title small,
.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta,
.widget_recent_entries .post-date,
.single-portfolio .meta-content,
.woocommerce ul.order_details li,
.product-style-3 .brand-info-wrapper .description,
.woocommerce-privacy-policy-text,
.elementor-widget-wp-widget-recent-posts .post-date,
.dokan-store-wrap .commentlist li p strong[itemprop="author"],
.dokan-store-wrap .commentlist li p em.verified,
.dokan-store-wrap .commentlist li p time,
.ts-testimonial-wrapper blockquote .author-role {
    font-size: 13px;
}

.header-contact-info>strong {
    font-size: 15px;
}

/*** Menu ***/
.ts-header .menu-wrapper .vertical-menu-wrapper .vertical-menu-heading,
.ts-header .menu-wrapper .ts-menu,
.ts-megamenu-container .elementor-widget-container>h5 {
    font-size: 17px;
    line-height: 22px;
}

.mobile-menu-wrapper .mobile-menu li,
.ts-header nav.main-menu li {
    line-height: 22px !important;
}

.mobile-menu-wrapper .mobile-menu ul.sub-menu,
.ts-header .menu-wrapper .vertical-menu-wrapper .vertical-menu,
.ts-header .menu-wrapper .ts-menu ul.sub-menu {
    font-size: 13px;
    line-height: 18px;
}

.ts-header .menu-wrapper .vertical-menu-wrapper .vertical-menu>ul>li {
    font-size: 14px;
}

.mobile-menu-wrapper .mobile-menu ul.sub-menu li,
.ts-header nav.main-menu ul.sub-menu li {
    line-height: 18px !important;
}

/*** Product ***/
.product-name,
h3.product-name,
.product-name h3,
.product .price del,
.woocommerce div.product div.summary,
.ts-product.style-list .product .price,
.ts-product.style-list .product .price del,
.ts-product.style-list .product .price ins,
.main-products.style-column-list .product .price,
.main-products.style-column-list .product .price del,
.main-products.style-column-list .product .price ins,
.woocommerce div.product .ts-product-brand-info .price,
.woocommerce div.product .ts-product-brand-info .price del,
.woocommerce div.product .ts-product-brand-info .price ins,
.yith-wfbt-section,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items li label>* {
    font-size: 13px;
    line-height: 16px;
}

.yith-wfbt-section .total_price_label {
    font-size: 13px;
}

.product.product-category .product-wrapper {
    font-size: 14px;
}

.product-brands,
.product-sku,
.product-categories,
.short-description,
.product .availability,
.ts-product-video-button,
.ts-product-360-button,
.woocommerce .product .category-name .count,
.products .product .meta-wrapper>.count-rating,
.woocommerce div.product .summary .woocommerce-product-rating,
.woocommerce div.product form.cart .variations,
.woocommerce div.product form.cart .reset_variations,
.woocommerce div.product form.cart .single_variation_wrap,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a.added,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons .added a:after,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons .ts-tooltip:before {
    font-size: 12px;
}

div.product .summary .meta-content,
div.product .summary .detail-meta-top {
    font-size: 12px;
}

/*** Button/input ***/
input,
textarea,
keygen,
select,
select option,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea,
.dokan-form-control,
.more-less-buttons a,
.woocommerce-columns>h3,
.hidden-title-form input[type="text"],
body .select2-container--default .select2-selectionSingle .select2-selection__rendered,
body select.dokan-form-control {
    font-size: 15px;
    line-height: 24px;
}

.button-text:not(.ts-banner):not(.elementor-widget-button),
.elementor-widget-button.button-text .elementor-button {
    font-size: 16px;
}

.button,
a.button,
button,
input[type^="submit"],
.shopping-cart p.buttons a,
a.wp-block-button__link,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce-page a.button,
.woocommerce-page button.button,
.woocommerce-page input.button,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce-page a.button.alt,
.woocommerce-page button.button.alt,
.woocommerce-page input.button.alt,
#content button.button,
.woocommerce #respond input#submit,
div.button a,
input[type="submit"].dokan-btn,
a.dokan-btn,
.dokan-btn,
.woocommerce .button.button-small,
.button.button-small,
.woocommerce .button.button-small.button-border,
.button.button-small.button-border,
.woocommerce-cart .cart-collaterals .shipping-calculator-form .button,
.wishlist_table .product-add-to-cart a,
body .woocommerce table.compare-list .add-to-cart td a,
.elementor-button-wrapper .elementor-button,
.elementor-widget-wp-widget-yith-woocompare-widget a.clear-all,
.elementor-widget-wp-widget-yith-woocompare-widget a.compare {
    font-size: 16px;
    line-height: 24px;
}

.yith-woocompare-widget a.clear-all,
.yith-woocompare-widget a.compare {
    line-height: 24px;
}

.elementor-button-wrapper .elementor-button.elementor-size-xs {
    font-size: 13px !important;
}

.elementor-button-wrapper .elementor-button.elementor-size-xl {
    font-size: 18px !important;
}

#left-sidebar .widget-container a.button,
#left-sidebar .widget-container button,
#left-sidebar .widget-container input[type^="submit"],
#left-sidebar .yith-woocompare-widget a.clear-all,
#left-sidebar .yith-woocompare-widget a.compare,
#left-sidebar .widget-container .dokan-btn,
#right-sidebar .widget-container a.button,
#right-sidebar .widget-container button,
#right-sidebar .widget-container input[type^="submit"],
#right-sidebar .yith-woocompare-widget a.clear-all,
#right-sidebar .yith-woocompare-widget a.compare,
#right-sidebar .widget-container .dokan-btn,
#ts-filter-widget-area .widget-container a.button,
#ts-filter-widget-area .widget-container button,
#ts-filter-widget-area .widget-container input[type^="submit"],
#ts-filter-widget-area .yith-woocompare-widget a.clear-all,
#ts-filter-widget-area .yith-woocompare-widget a.compare,
#ts-filter-widget-area .widget-container .dokan-btn,
#add_payment_method table.cart td.actions .coupon .input-text,
.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce-checkout table.cart td.actions .coupon .input-text,
.woocommerce-cart .cart-collaterals .shipping-calculator-form .button,
.woocommerce-cart table.cart td.actions .button {
    font-size: 14px;
}

/*** Heading ***/
h1,
.h1,
.h1 .elementor-heading-title,
article.single-portfolio .entry-content>.entry-title,
.woocommerce div.product .summary p.price,
.woocommerce div.product .summary span.price,
.main-content-fullwidth #main-content.ts-col-24 article.single .entry-header .entry-title,
.layout-fullwidth #main-content.ts-col-24 article.single .entry-header .entry-title {
    font-size: 40px;
    line-height: 56px;
}

h2,
.h2,
.h2 .elementor-heading-title,
.breadcrumb-title-wrapper .page-title,
.related-portfolios .shortcode-title,
article.single .entry-header .entry-title,
.woocommerce div.product .summary .entry-title,
.woocommerce .cross-sells>h2,
.woocommerce .up-sells>h2,
.woocommerce .related>h2,
.woocommerce.related>h2,
.theme-title .heading-title,
.yith-wfbt-section .total_price,
.woocommerce div.product>.ts-mailchimp-subscription-shortcode .widget-title,
.style-tabs-vertical .column-tabs .heading-tab .heading-title,
.ts-shortcode .shortcode-heading-wrapper .shortcode-title {
    font-size: 30px;
    line-height: 42px;
}

h3,
.h3,
.h3 .elementor-heading-title,
.ts-blogs.columns-1 article .entry-title,
.columns-1 .list-posts article .entry-title,
.ts-blogs.columns-2 article .entry-title,
.columns-2 .list-posts article .entry-title,
.columns-0 .list-posts article:nth-child(1) .entry-title,
.columns-0 .list-posts article:nth-child(2) .entry-title,
.page-container:not(.columns-0):not(.columns-1):not(.columns-2):not(.columns-3) .list-posts article .entry-title,
.woocommerce div.product .woocommerce-tabs ul.tabs li,
.ts-portfolio-wrapper .portfolio-meta h4,
.yith-wfbt-section>h3,
.woocommerce-MyAccount-content form>h3,
#customer_login h2,
.woocommerce-order-details>h2,
.woocommerce .cart-collaterals .order-total .amount,
.dokan-dashboard h1.entry-title,
.dokan-dashboard header.dokan-dashboard-header h1,
#main-content .woocommerce.columns-1>.products .product .meta-wrapper-2 .price {
    font-size: 25px;
    line-height: 32px;
}

h4,
.h4,
.h4 .elementor-heading-title,
#main-content .woocommerce.columns-1>.products .product .product-name,
.widget-container .widget-title-wrapper,
.widget-container .wp-block-group h2,
.woocommerce .cart-collaterals .cart_totals>h2,
.woocommerce-billing-fields>h3,
.woocommerce>form.checkout #order_review_heading,
.elementor-widget-image-box .elementor-image-box-title {
    font-size: 22px;
    line-height: 28px;
}

h5,
.h5,
.h5 .elementor-heading-title,
.widget-container .widget-title,
.comments-title .heading-title,
.ts-search-by-category>h2,
.dropdown-container .theme-title,
#comment-wrapper .heading-title,
.woocommerce #reviews #comments h2,
#reviews .woocommerce-Reviews-title,
.column-tabs ul.tabs li,
.style-tabs-default .column-tabs .heading-tab .heading-title,
#main-content.ts-col-24 .frequently-bought-together-vertical .yith-wfbt-section>h3,
.ts-megamenu .elementor-widget .elementor-widget-container .banner-wrapper h5,
.elementor-widget[data-widget_type*="wp-widget-"] h2.widgettitle,
.ts-product.style-list .shortcode-heading-wrapper .shortcode-title {
    font-size: 20px;
    line-height: 26px;
}

h6,
.h6,
.h6 .elementor-heading-title,
.ts-blogs article .entry-title,
.list-posts article .entry-title,
.filter-widget-area .widget-container .widget-title,
.ts-megamenu .elementor-widget .elementor-widget-container h5,
.footer-container .elementor-widget .elementor-widget-container h5,
.footer-container .ts-list-of-product-categories-wrapper h3.heading-title,
body .dokan-category-menu h3.widget-title,
body .dokan-widget-area .widget .widget-title,
body .cart-empty.woocommerce-info,
.ts-team-members h3,
.commentlist li #comment-wrapper .heading-title,
.woocommerce-account .addresses .title h3,
.woocommerce-account .addresses h2,
.woocommerce-customer-details .addresses h2,
.ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li,
.woocommerce .tabs-in-summary #reviews #comments .woocommerce-Reviews-title {
    font-size: 17px;
    line-height: 22px;
}

body:not(.woocommerce.archive) #left-sidebar .widget-container .widget-title-wrapper .widget-title,
body:not(.woocommerce.archive) #left-sidebar .widget-container .widget-title-wrapper .widgettitle,
body:not(.woocommerce.archive) #left-sidebar .widget-container .wp-block-group h2,
body:not(.woocommerce.archive) #right-sidebar .widget-container .widget-title-wrapper .widget-title,
body:not(.woocommerce.archive) #right-sidebar .widget-container .widget-title-wrapper .widgettitle,
body:not(.woocommerce.archive) #right-sidebar .widget-container .wp-block-group h2,
.brand-info-wrapper h3 {
    font-size: 16px;
}

/*** Responsive font size ***/
@media only screen and (max-width: 1400px) {

    #tab-description .ts-row .h1,
    .woocommerce div.product.product-style-3 .summary p.price {
        font-size: 30px;
        line-height: 42px;
    }

    .woocommerce div.product .summary p.price,
    .woocommerce div.product .summary span.price {
        font-size: 35px
    }

    .yith-wfbt-section .total_price {
        font-size: 25px
    }
}

@media only screen and (min-width: 1279px) {

    .show-filter-top .product-filter-by-brand ul li label,
    .show-filter-top .product-filter-by-availability ul li label,
    .show-filter-top .product-filter-by-color ul li a {
        font-family: Helvetica;
        font-style: normal;
        font-weight: 500;
    }
}

@media only screen and (max-width: 1279px) {

    h1,
    .h1,
    .h1 .elementor-heading-title,
    article.single-portfolio .entry-content>.entry-title,
    .woocommerce div.product .summary p.price,
    .woocommerce div.product .summary span.price,
    .main-content-fullwidth #main-content.ts-col-24 article.single .entry-header .entry-title,
    .layout-fullwidth #main-content.ts-col-24 article.single .entry-header .entry-title {
        font-size: 30px;
        line-height: 42px;
    }

    h2,
    .h2,
    .h2 .elementor-heading-title,
    .breadcrumb-title-wrapper .page-title,
    .related-portfolios .shortcode-title,
    article.single .entry-header .entry-title,
    .woocommerce div.product .summary .entry-title,
    .woocommerce .cross-sells>h2,
    .woocommerce .up-sells>h2,
    .woocommerce .related>h2,
    .woocommerce.related>h2,
    .theme-title .heading-title,
    .yith-wfbt-section .total_price,
    .woocommerce div.product>.ts-mailchimp-subscription-shortcode .widget-title,
    .ts-shortcode .shortcode-heading-wrapper .shortcode-title {
        font-size: 25px;
        line-height: 32px;
    }

    h3,
    .h3,
    .h3 .elementor-heading-title,
    .ts-blogs.columns-1 article .entry-title,
    .columns-1 .list-posts article .entry-title,
    .ts-blogs.columns-2 article .entry-title,
    .columns-2 .list-posts article .entry-title,
    .columns-0 .list-posts article:nth-child(1) .entry-title,
    .page-container:not(.columns-0):not(.columns-1):not(.columns-2):not(.columns-3) .list-posts article .entry-title,
    .woocommerce div.product .woocommerce-tabs ul.tabs li,
    #reviews .woocommerce-Reviews-title,
    .yith-wfbt-section>h3,
    .woocommerce-MyAccount-content form>h3,
    #customer_login h2,
    .woocommerce-order-details>h2,
    .woocommerce .cart-collaterals .order-total .amount,
    .dokan-dashboard h1.entry-title,
    .dokan-dashboard header.dokan-dashboard-header h1,
    .style-tabs-default .column-tabs .heading-tab .heading-title,
    .style-tabs-vertical .column-tabs .heading-tab .heading-title,
    #main-content .woocommerce.columns-1>.products .product .meta-wrapper-2 .price {
        font-size: 22px;
        line-height: 28px;
    }

    h4,
    .h4,
    .h4 .elementor-heading-title,
    #main-content .woocommerce.columns-1>.products .product .product-name,
    .widget-container .widget-title-wrapper,
    .widget-container .wp-block-group h2,
    .woocommerce .cart-collaterals .cart_totals>h2,
    .woocommerce-billing-fields>h3,
    .woocommerce>form.checkout #order_review_heading,
    .elementor-widget-image-box .elementor-image-box-title,
    .column-tabs ul.tabs li {
        font-size: 20px;
        line-height: 26px;
    }

    h5,
    .h5,
    .h5 .elementor-heading-title,
    .widget-container .widget-title,
    .comments-title .heading-title,
    .ts-search-by-category>h2,
    .dropdown-container .theme-title,
    #comment-wrapper .heading-title,
    .woocommerce #reviews #comments h2,
    #reviews .woocommerce-Reviews-title,
    #main-content.ts-col-24 .frequently-bought-together-vertical .yith-wfbt-section>h3,
    .elementor-widget[data-widget_type*="wp-widget-"] h2.widgettitle,
    .ts-portfolio-wrapper .portfolio-meta h4 {
        font-size: 17px;
        line-height: 22px;
    }

    h6,
    .h6,
    .h6 .elementor-heading-title,
    .ts-blogs article .entry-title,
    .list-posts article .entry-title,
    .columns-0 .list-posts article:nth-child(2) .entry-title,
    .filter-widget-area .widget-container .widget-title,
    .ts-megamenu .elementor-widget .elementor-widget-container h5,
    .footer-container .elementor-widget .elementor-widget-container h5,
    .footer-container .ts-list-of-product-categories-wrapper h3.heading-title,
    body .dokan-category-menu h3.widget-title,
    body .dokan-widget-area .widget .widget-title,
    body .cart-empty.woocommerce-info,
    .ts-team-members h3,
    .commentlist li #comment-wrapper .heading-title,
    .woocommerce-account .addresses .title h3,
    .woocommerce-account .addresses h2,
    .woocommerce-customer-details .addresses h2,
    body:not(.woocommerce.archive) #left-sidebar .widget-container .widget-title-wrapper .widget-title,
    body:not(.woocommerce.archive) #left-sidebar .widget-container .widget-title-wrapper .widgettitle,
    body:not(.woocommerce.archive) #left-sidebar .widget-container .wp-block-group h2,
    body:not(.woocommerce.archive) #right-sidebar .widget-container .widget-title-wrapper .widget-title,
    body:not(.woocommerce.archive) #right-sidebar .widget-container .widget-title-wrapper .widgettitle,
    body:not(.woocommerce.archive) #right-sidebar .widget-container .wp-block-group h2,
    .ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li,
    .woocommerce .tabs-in-summary #reviews #comments .woocommerce-Reviews-title {
        font-size: 15px;
        line-height: 20px;
    }

    /*** Menu ***/
    .mobile-menu-wrapper .mobile-menu,
    .ts-header .menu-wrapper .vertical-menu-wrapper .vertical-menu-heading,
    .ts-megamenu-container .elementor-widget-container>h5,
    .ts-header .menu-wrapper .ts-menu {
        font-size: 15px;
    }

    input,
    textarea,
    keygen,
    select,
    select option,
    body .select2-container,
    .woocommerce form .form-row input.input-text,
    .woocommerce form .form-row textarea,
    .dokan-form-control,
    .more-less-buttons a,
    #add_payment_method table.cart td.actions .coupon .input-text,
    .woocommerce-cart table.cart td.actions .coupon .input-text,
    .woocommerce-checkout table.cart td.actions .coupon .input-text,
    .woocommerce-columns>h3,
    .hidden-title-form input[type="text"] {
        font-size: 14px;
        line-height: 18px;
    }

    .button-text:not(.ts-banner):not(.elementor-widget-button),
    .elementor-widget-button.button-text .elementor-button {
        font-size: 15px;
    }

    .button,
    a.button,
    button,
    input[type^="submit"],
    .shopping-cart p.buttons a,
    a.wp-block-button__link,
    .woocommerce a.button,
    .woocommerce button.button,
    .woocommerce input.button,
    .woocommerce-page a.button,
    .woocommerce-page button.button,
    .woocommerce-page input.button,
    .woocommerce a.button.alt,
    .woocommerce button.button.alt,
    .woocommerce input.button.alt,
    .woocommerce-page a.button.alt,
    .woocommerce-page button.button.alt,
    .woocommerce-page input.button.alt,
    #content button.button,
    .woocommerce #respond input#submit,
    div.button a,
    input[type="submit"].dokan-btn,
    a.dokan-btn,
    .dokan-btn,
    .woocommerce .button.button-small,
    .button.button-small,
    .woocommerce .button.button-small.button-border,
    .button.button-small.button-border,
    .woocommerce-cart .cart-collaterals .shipping-calculator-form .button,
    .wishlist_table .product-add-to-cart a,
    body .woocommerce table.compare-list .add-to-cart td a,
    .elementor-button-wrapper .elementor-button,
    .elementor-widget-wp-widget-yith-woocompare-widget a.clear-all,
    .elementor-widget-wp-widget-yith-woocompare-widget a.compare {
        font-size: 15px;
        line-height: 18px;
    }

    .elementor-button-wrapper .elementor-button.elementor-size-xs {
        font-size: 12px !important;
    }

    .elementor-button-wrapper .elementor-button.elementor-size-xl {
        font-size: 17px;
    }

    .entry-meta-middle,
    .entry-meta-bottom {
        font-size: 12px;
    }
}

@media only screen and (max-width: 767px) {
    .columns-0 .list-posts article:nth-child(1) .entry-title {
        font-size: 15px;
        line-height: 20px;
    }
}

/*--------------------------------------------------------
		III. CUSTOM COLOR
	---------------------------------------------------------*/
/*** Background Content Color ***/
body #main,
body.dokan-store #main:before,
#cboxLoadedContent,
.shopping-cart-wrapper .dropdown-container:before,
.my-account-wrapper .dropdown-container:before,
form.checkout div.create-account,
.ts-popup-modal .popup-container,
body #ts-search-result-container:before,
#yith-wcwl-popup-message,

.dataTables_wrapper,
body>.compare-list,
.single-navigation>div .product-info:before,
.single-navigation .product-info:before,
.archive.ajax-pagination .woocommerce>.products:after,
.dropdown-container ul.cart_list li.loading:before,
.thumbnail-wrapper .button-in.wishlist>a.loading:before,
.meta-wrapper .button-in.wishlist>a.loading:before,
.woocommerce a.button.loading:before,
.woocommerce button.button.loading:before,
.woocommerce input.button.loading:before,
div.blockUI.blockOverlay:before,
.woocommerce .blockUI.blockOverlay:before,
div.product .single-navigation a .product-info,
.ts-floating-sidebar .ts-sidebar-content,
.mobile-menu-wrapper ul.sub-menu,
.ts-team-members .team-info,
.mobile-menu-wrapper li.active .ts-menu-drop-icon.active,
.woocommerce .woocommerce-ordering .orderby ul:before,
.product-per-page-form ul.perpage ul:before,
#comments .wcpr-filter-container ul.wcpr-filter-button-ul,
.single-post .entry-header-meta .entry-header-meta-content,
.vertical-categories-wrapper .products .product-category,
.archive.woocommerce .woocommerce .product-wrapper,
.shopping-cart-wrapper .dropdown-container:before,
.my-account-wrapper .dropdown-container:before,
.wcml_currency_switcher>ul:before,
.wpml-ls-legacy-dropdown ul.wpml-ls-sub-menu:before,
.wpml-ls-item-legacy-dropdown-click ul.wpml-ls-sub-menu:before {
    background-color: #ffffff;
}

.ts-tiny-cart-wrapper .total {
    box-shadow: 0 -1px 0 #ffffff;
}

.ts-tiny-cart-wrapper li div.blockUI.blockOverlay,
.widget_shopping_cart li div.blockUI.blockOverlay,
.elementor-widget-wp-widget-woocommerce_widget_cart li div.blockUI.blockOverlay {
    background-color: #ffffff !important;
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger,
.portfolio-thumbnail>figure~a.like {
    background: #ffffff;
    border-color: #ffffff;
    color: #4f4f4f;
}

blockquote {
    background: #FFF6EC;
    color: #4f4f4f;
}

/*** Body Text Color ***/
body,
body table.compare-list,
.comment-author-link a,
.widget-container li>a,
.widget_categories li>a,
.widget_archive li>a,
.wp-block-archives-list li>a,
.header-middle .header-currency ul,
.header-middle .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
.header-middle .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
.header-top .header-currency ul,
.header-top .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
.header-top .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
body .header-top .dropdown-container,
body .header-middle .dropdown-container,
body .header-bottom .dropdown-container,
footer#colophon .wcml_currency_switcher>ul,
footer#colophon .wpml-ls-legacy-dropdown ul.wpml-ls-sub-menu,
footer#colophon .wpml-ls-item-legacy-dropdown-click ul.wpml-ls-sub-menu,
.ts-testimonial-wrapper blockquote .author-role .author,
.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta,
.my-account-wrapper .dropdown-container,
.ul-style.primary-color li,
.header-transparent.header-text-light .dropdown-container,
body.header-transparent.header-text-light .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
body.header-transparent.header-text-light .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
.header-transparent.header-text-light .header-currency ul,
.button-text:not(.ts-banner):not(.elementor-widget-button),
.ts-product.style-grid.has-shop-more-button .shop-more .button-text,
.ts-product.style-grid.has-shop-more-button .shop-more .button-text:hover,
.elementor-widget-button.button-text .elementor-button,
.elementor-widget-button.button-text .elementor-button:hover,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce-page .woocommerce-error .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-message .button,
.owl-nav>div:before,
.ts-social-sharing li a,
.woocommerce-info,
.woocommerce .woocommerce-info,
.alert.alert-success,
div.wpcf7-mail-sent-ok,
#yith-wcwl-popup-message,
.woocommerce div.product .woocommerce-tabs ul.tabs li a:hover,
.woocommerce div.product .woocommerce-tabs ul.tabs li.active a,
.woocommerce.yith-wfbt-section .yith-wfbt-images td>a,
#ts-product-360-modal.ts-popup-modal .close,
.more-less-buttons>a,
.woocommerce-product-rating a,
.woocommerce-product-rating a:hover,
#reviews>.woocommerce-product-rating .review-count,
.woocommerce-privacy-policy-text,
.woocommerce>form.checkout a,
body .hidden-title-form a,
.ts-product-video-button,
.ts-product-360-button,
.dokan-store-wrap .commentlist li p time,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a {
    color: #4f4f4f;
}

.owl-dot.active,
.social-icons .list-icons .ts-tooltip {
    background: #4f4f4f;
}

.social-icons .list-icons .ts-tooltip:before {
    border-top-color: #4f4f4f;
}

.threesixty .nav_bar a {
    background: #4f4f4f;
    border-color: #4f4f4f;
    color: #ffffff;
}

.threesixty .nav_bar a:hover {
    color: #4f4f4f;
}

.thumbnail-wrapper .product-group-button>div {
    background-color: #ffffff;
}

.product-group-button>div a:after,
.product-group-button-meta>div.button-in a:before {
    color: #4f4f4f;
}

.product-group-button-meta>div a.added:before,
.product-group-button-meta>div .added a:before,
.product-group-button>div a.added:after,
.product-group-button>div .added a:after,
.product_list_widget .button-in a:hover,
.woocommerce .product_list_widget .button-in a:hover,
.product_list_widget .button-in .added a:after,
.product_list_widget .button-in a.added:after {
    color: #FE7934;
}

.tags-link a,
.wp-block-tag-cloud a,
.tagcloud a {
    background-color: #ffffff;
    border-color: #e5dada;
    color: #4f4f4f;
}

.tags-link a:hover,
.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
    background-color: #ff002b;
    border-color: #ff002b;
    color: #ffffff;
}

.pagination-wrap ul.pagination>li>a.prev,
.pagination-wrap ul.pagination>li>a.next,
.dokan-pagination-container .dokan-pagination li:first-child a,
.dokan-pagination-container .dokan-pagination li:last-child a,
.woocommerce nav.woocommerce-pagination ul li a.next,
.woocommerce nav.woocommerce-pagination ul li a.prev,
.product-group-button .button-tooltip,
.ts-pagination ul li a.prev,
.ts-pagination ul li a.next {
    background-color: #ff002b !important;
    border-color: #ff002b !important;
    color: #ffffff !important;
}

.product-group-button .button-tooltip:after {
    border-left-color: #ff002b;
}

.rtl .product-group-button .button-tooltip:after {
    border-right-color: #ff002b;
}

.ts-pagination ul li a:focus,
.ts-pagination ul li a:hover,
.ts-pagination ul li span.current,
.pagination-wrap ul.pagination>li>a:hover,
.pagination-wrap ul.pagination>li>span.current,
.dokan-pagination-container .dokan-pagination li:hover a,
.dokan-pagination-container .dokan-pagination li.active a,
.woocommerce nav.woocommerce-pagination ul li a:focus,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span.current,
.pagination-wrap ul.pagination>li>a.prev:hover,
.pagination-wrap ul.pagination>li>a.next:hover,
.dokan-pagination-container .dokan-pagination li:first-child a:hover,
.dokan-pagination-container .dokan-pagination li:last-child a:hover,
.woocommerce nav.woocommerce-pagination ul li a.next:hover,
.woocommerce nav.woocommerce-pagination ul li a.prev:hover,
.ts-pagination ul li a.prev:hover,
.ts-pagination ul li a.next:hover {
    color: #ff002b !important;
    border-color: #ff002b !important;
}

.woocommerce a.remove,
.ts-floating-sidebar .close,
.cart_list li a.remove,
ul.products-list a.remove,
table.shop_table .product-remove a,
table.compare-list tr.remove td>a .remove,
.woocommerce .woocommerce-widget-layered-nav-dropdown .woocommerce-widget-layered-nav-dropdown__submit {
    color: #4f4f4f !important;
}

.entry-meta-middle,
.entry-author .role,
.widget-container .count,
.ts-testimonial-wrapper blockquote .author-role,
.woocommerce-review__published-date,
.commentlist li .date-time,
.ts-search-result-container .description,
.product-style-3 .brand-info-wrapper .description,
.woocommerce-privacy-policy-text,
div.product .summary .meta-content,
.tagcloud .tag-link-count,
.product-brands,
.product-sku,
.product-categories,
.woocommerce div.product .woocommerce-tabs ul.tabs li a,
.woocommerce-MyAccount-content>form .form-row>span>em,
.yith-wcwl-share .yith-wcwl-after-share-section,
.widget_archive li,
.wp-block-archives-list li,
.widget_categories li,
.elementor-widget-wp-widget-categories li,
.product-filter-by-color ul li .count,
ul.product_list_widget li .reviewer,
.elementor-widget-wp-widget-recent-posts .post-date,
#cancel-comment-reply-link,
.meta-wrapper .short-description,
.ts-product-brand-wrapper .item .count,
.woocommerce .widget_rating_filter ul li a,
.product-filter-by-brand li label .count,
.my-account-wrapper .form-content>form>a.register,
.comments-area .navigation .nav-previous+.nav-next:before,
.commentlist li.comment .comment-actions a {
    color: #808080;
}

/*** Heading Text Color ***/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
dt,
label,
p>label,
fieldset div>label,
blockquote,
blockquote .author,
table thead th,
.wpcf7 p,
.woocommerce>form>fieldset legend,
.woocommerce table.shop_table th,
html input:focus:invalid:focus,
html select:focus:invalid:focus,
#yith-wcwl-popup-message,
table#wp-calendar thead th,
html body>h1,
.woocommerce table.shop_attributes th,
.column-tabs ul.tabs li,
.ts-banner.text-under-image .box-content .description,
.ts-banner.text-under-image .box-content h2,
.ts-banner.text-under-image .box-content h6,
.ts-banner.text-under-image.style-arrow .ts-banner-button a,
body table.compare-list th,
body table.compare-list tr.title th,
body table.compare-list tr.image th,
body table.compare-list tr.price th {
    color: #4f4f4f;
}

.column-tabs ul.tabs li:after {
    border-color: #4f4f4f;
}

/*** Primary color ***/
.primary-color,
.hightlight,
.ul-style.primary-color li:before,
.woocommerce-tabs .ul-style li:before,
.short-description .ul-style li:before,
.woocommerce-product-details__short-description .ul-style li:before,
blockquote:before,
.out-of-stock .availability-text,
.woocommerce div.product form.cart .woocommerce-variation-availability p.stock.out-of-stock,
.elementor-lightbox .dialog-lightbox-close-button:hover,
.ts-store-notice .close:hover,
body #cboxClose:hover:after,
html body>h1 a.close:hover,
body table.compare-list tr.remove td>a:hover,
.elementor-lightbox .elementor-swiper-button:hover,
.woocommerce-account .addresses .title .edit:hover:before,
body .hidden-title-form a:hover,
.ts-header a:hover,
.product .meta-wrapper a:hover,
.meta-bottom-2 a:hover,
.filter-widget-area-button a:hover,
.filter-widget-area-button a.active,
.my-account-wrapper .dropdown-container a:hover,
body .wpml-ls-legacy-dropdown a:hover,
body .wpml-ls-legacy-dropdown a:focus,
body .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a,
body .wpml-ls-legacy-dropdown-click a:hover,
body .wpml-ls-legacy-dropdown-click a:focus,
body .wpml-ls-legacy-dropdown-click .wpml-ls-current-language:hover>a,
.ts-search-result-container .view-all-wrapper a:hover,
.widget_text a[href^="mailto"]:hover,
.widget_text a[href^="tel"]:hover,
.woocommerce .ts-search-result-container ul.product_list_widget ul.ul-style li:before,
.woocommerce.ts-search-result-container ul.product_list_widget ul.ul-style li:before,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a:hover {
    color: #00B412;
}

.ts-language-switcher a:hover,
.ts-currency-switcher a:hover,
#ts-product-video-modal .close:hover,
ul.products-list a.remove:hover,
table.shop_table .product-remove a:hover,
table.compare-list tr.remove td>a .remove:hover,
.cart_list li a.remove:hover {
    color: #ff002b !important;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range:before {
    background: #ff002b;
}

.ts-header .ts-menu ul li:hover:before,
.ts-header .ts-menu ul li:hover>a,
.ts-header .ts-menu ul li.current-menu-item:before,
.ts-header .ts-menu ul li.current-menu-item>a,
.ts-header .ts-menu ul li.current-menu-parent>a,
.ts-header .ts-menu ul li.current-menu-ancestor>a,
.ts-header .ts-menu ul li.current-product_cat-ancestor>a,
.ts-header .ts-menu ul li.current-menu-item .ts-menu-drop-icon,
.ts-header .ts-menu ul li.current-menu-parent .ts-menu-drop-icon,
.ts-header .ts-menu ul li.current-menu-ancestor .ts-menu-drop-icon,
.ts-header .ts-menu ul .sub-menu li.current-menu-item>a,
.ts-header .ts-menu ul .sub-menu li.current-menu-parent>a,
.ts-header .ts-menu ul .sub-menu li.current-menu-ancestor>a,
.ts-header .ts-menu ul .sub-menu li.current-product_cat-ancestor>a,
.ts-header .ts-menu ul .sub-menu li.current-menu-item .ts-menu-drop-icon,
.ts-header .ts-menu ul .sub-menu li.current-menu-parent .ts-menu-drop-icon,
.ts-header .ts-menu ul .sub-menu li.current-menu-ancestor .ts-menu-drop-icon,
#group-icon-header .social-icons ul li a:hover,
.ts-header .social-icons ul li a:hover,
.ts-list-of-product-categories-wrapper .list-categories ul li a:hover,
.footer-container .elementor-widget-wp-widget-nav_menu ul.menu li a:hover,
.footer-container ul.nostyle li a:hover,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li>a .menu-label:before,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-menu-item>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current_page_parent>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-menu-parent>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current_page_item>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-menu-ancestor>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-page-ancestor>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-product_cat-ancestor>a {
    color: #ff002b !important;
}

.ts-banner .box-content .sale-label,
.add-to-cart-popup-content .heading .theme-title,
.ts-portfolio-wrapper .item-wrapper a.like,
.portfolio-info .portfolio-like {
    background: #ff002b;
    border-color: #ff002b;
    color: #ffffff;
}

#to-top a,
.my-account-wrapper .ts-style-text .account-control>a:hover,
.header-text-light .my-account-wrapper .ts-style-text .account-control>a:hover,
.woocommerce-account .woocommerce-MyAccount-navigation li a:hover,
.woocommerce-account .woocommerce-MyAccount-navigation li.is-active a {
    background-color: #ff002b;
    color: #ffffff;
}

.product-on-sale-form:hover label:before,
.product-on-sale-form.checked label:before,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items li span.checkboxbutton.checked,
.widget-container.product-filter-by-brand ul>li label:hover:before,
.widget-container.product-filter-by-brand ul>li.selected label:before,
.product-filter-by-availability ul li label:hover:before,
.product-filter-by-availability ul li input[checked="checked"]+label:before,
.product-filter-by-price ul li label:hover:before,
.product-filter-by-price ul li.chosen label:before,
.woocommerce .widget_rating_filter ul li a:hover::before,
.woocommerce .widget_rating_filter ul li.chosen a::before,
.product-filter-by-color ul li a:hover:before,
.product-filter-by-color ul li.chosen a:before {
    background: #ff002b;
    border-color: #ff002b !important;
}

.product-on-sale-form:hover label:after,
.product-on-sale-form.checked label:after,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items li span.checkboxbutton.checked:after,
.widget-container.product-filter-by-brand ul>li label:hover:after,
.widget-container.product-filter-by-brand ul>li.selected label:after,
.product-filter-by-availability ul li label:hover:after,
.product-filter-by-availability ul li input[checked="checked"]+label:after,
.product-filter-by-price ul li label:hover:after,
.product-filter-by-price ul li.chosen label:after,
.woocommerce .widget_rating_filter ul li a:hover::after,
.woocommerce .widget_rating_filter ul li.chosen a::after,
.product-filter-by-color ul li a:hover:after,
.product-filter-by-color ul li.chosen a:after {
    color: #ffffff;
}

.woocommerce div.product div.images .flex-control-thumbs li img.flex-active,
.woocommerce div.product div.images .flex-control-thumbs li img:hover {
    border-color: #ff002b;
}

#review_form .cr-upload-images-preview .cr-upload-images-pbar .cr-upload-images-pbarin,
.cr-upload-images-preview .cr-upload-images-pbarin {
    background-color: #ff002b;
}

#review_form .cr-upload-images-preview .cr-upload-images-containers .cr-upload-images-delete,
.cr-upload-images-preview .cr-upload-images-containers .cr-upload-images-delete {
    background-color: #ff002b;
    color: #ffffff;
}

.list-posts article.sticky {
    border-top-color: #ff002b;
}

.summary-column-2 .ts-product-attribute>div.option:not(.color).selected>a,
.summary-column-2 .ts-product-attribute>div.option:not(.color):hover>a,
.ts-product-attribute>div.option:not(.color).selected>a,
.ts-product-attribute>div.option:not(.color):hover>a {
    background: #ECFDEE;
    border-color: #ff002b;
}

/*** Secondary color ***/
.yith-wfbt-section .total_price,
.availability .availability-text,
.header-contact-info:before {
    color: #FF6D22;
}

.woocommerce.yith-wfbt-section:before {
    background-color: #FFF6EC;
}

#group-icon-header .tab-mobile-menu li.active,
.woocommerce.yith-wfbt-section .yith-wfbt-images td:not(:last-child)>a:after {
    background: #FF6D22;
    color: #ffffff;
}

.ts-delivery-note>span,
.woocommerce div.product div.summary .ts-delivery-note>span {
    border-color: #FF6D22;
}

.ts-delivery-note svg path {
    stroke: #FF6D22;
}

/*** Link Color ***/
a,
.elementor-widget-text-editor table a {
    color: #ff002b;
}

a:hover,
.elementor-widget-text-editor table a:hover,
.product-brands a:hover,
.product-categories a:hover,
.portfolio-info .cat-links a:hover,
.widget_categories>ul li>a:hover,
.widget_archive li>a:hover,
.widget_nav_menu li>a:hover,
.widget_pages li>a:hover,
.widget_meta li>a:hover,
.widget_recent_comments li>a:hover,
.widget_recent_entries li>a:hover,
.widget_rss li>a:hover,
ul.product_list_widget li a:hover,
.woocommerce ul.cart_list li a:hover,
.woocommerce ul.product_list_widget li a:hover,
.ts-product-brand-info .social-profile li a:hover,
.ts-header .menu-wrapper .vertical-menu-wrapper ul.sub-menu a:hover,
.ts-header .menu-wrapper .ts-menu ul.sub-menu a:hover,
.vertical-categories-wrapper .products .product-category .heading-title a:hover,
.woocommerce-product-rating .woocommerce-review-link:hover,
.woocommerce div.product .summary .woocommerce-product-rating .woocommerce-review-link:hover,
.widget_categories>ul li.current-cat>a,
.woocommerce div.product .summary .product-brands a:hover,
.woocommerce div.product .summary .cat-links a:hover,
.woocommerce div.product .summary .tag-links a:hover,
.ts-product-category-wrapper .product:not(.product-category) .category-name a:hover,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items a:hover,
.elementor-widget[data-widget_type*="wp-widget-"] ul li a:hover,
.comments-area .add-comment>a:hover,
.commentlist li.comment .comment-actions a:hover,
.woocommerce .woocommerce-ordering ul li a:hover,
.product-per-page-form ul.perpage ul li a:hover {
    color: #ff002b;
}

.item-wrapper .portfolio-meta a:hover {
    color: #ff002b !important;
}

/*** Button/Input Color ***/
.button,
a.button,
button,
input[type^="submit"],
.shopping-cart p.buttons a,
a.wp-block-button__link,
.is-style-outline>.wp-block-button__link:not(.has-text-color):hover,
.wp-block-button__link.is-style-outline:not(.has-text-color):hover,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce-page a.button,
.woocommerce-page button.button,
.woocommerce-page input.button,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce-page a.button.alt,
.woocommerce-page button.button.alt,
.woocommerce-page input.button.alt,
#content button.button,
.wp-block-search .wp-block-search__button,
.woocommerce #respond input#submit,
div.button a,
input[type="submit"].dokan-btn,
a.dokan-btn,
.dokan-btn,
.dokan-btn.dokan-store-list-filter-button,
.dokan-btn.dokan-store-list-filter-button:hover,
.elementor-button-wrapper .elementor-button,
.wishlist_table .product-add-to-cart a,
body .woocommerce table.compare-list .add-to-cart td a,
.yith-woocompare-widget a.clear-all,
.elementor-widget-wp-widget-yith-woocompare-widget a.clear-all,
.woocommerce-cart table.cart td.actions>.button:hover,
div.wpcf7 input[type^="submit"],
.woocommerce a.button.disabled,
.woocommerce a.button.disabled:hover,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce a.button:disabled[disabled]:hover,
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled],
.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt:disabled[disabled]:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt:disabled[disabled],
.woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt:disabled[disabled]:hover {
    background: #ff002b;
    border-color: #ff002b;
    color: #ffffff;
}

div.wpcf7 input[type^="submit"]:hover,
.button:hover,
a.button:hover,
button:hover,
input[type^="submit"]:hover,
.shopping-cart p.buttons a:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce-page a.button:hover,
.woocommerce-page button.button:hover,
.woocommerce-page input.button:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover,
.woocommerce-page a.button.alt:hover,
.woocommerce-page button.button.alt:hover,
.woocommerce-page input.button.alt:hover,
#content button.button:hover,
.wp-block-search .wp-block-search__button:hover,
.woocommerce #respond input#submit:hover,
div.button a:hover,
input[type="submit"].dokan-btn:hover,
a.dokan-btn:hover,
.dokan-btn:hover,
.wishlist_table .product-add-to-cart a:hover,
a.wp-block-button__link:hover,
.is-style-outline>.wp-block-button__link:not(.has-text-color),
.wp-block-button__link.is-style-outline:not(.has-text-color),
.elementor-button-wrapper .elementor-button:hover,
body .woocommerce table.compare-list .add-to-cart td a:hover,
.woocommerce-cart table.cart td.actions>.button,
.yith-woocompare-widget a.clear-all:hover,
.elementor-widget-wp-widget-yith-woocompare-widget a.clear-all:hover {
    color: #ff002b;
    border-color: #ff002b;
}

.button-primary,
.button.button-primary,
#content button.button.button-primary,
.shop-more a.button,
.woocommerce-page button.button.button-primary,
.yith-wfbt-form .yith-wfbt-submit-block .button,
form.track_order input[type^="submit"],
form.track_order button[type^="submit"],
.woocommerce-MyAccount-content form button[type^="submit"],
.woocommerce form.woocommerce-ResetPassword.lost_reset_password button[type^="submit"],
.load-more-wrapper .button,
.ts-shop-load-more .button,
.woocommerce .ts-shop-load-more .button,
.woocommerce-cart .return-to-shop a.button,
.widget_shopping_cart .buttons a.checkout:hover,
.woocommerce #customer_login form.login .button,
.woocommerce #customer_login form.register .button,
.yith-woocompare-widget a.compare,
.elementor-widget-wp-widget-yith-woocompare-widget a.compare,
.elementor-widget-wp-widget-woocommerce_widget_cart .buttons a.checkout,
#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
.dropdown-footer>a.button.checkout-button:hover {
    background: #ff002b;
    border-color: #ff002b;
    color: #ffffff;
}

.button-primary:hover,
.button.button-primary:hover,
.shop-more a.button:hover,
#content button.button.button-primary:hover,
.woocommerce-page button.button.button-primary:hover,
.woocommerce div.product form.cart .button:hover,
.yith-wfbt-form .yith-wfbt-submit-block .button:hover,
form.track_order input[type^="submit"]:hover,
form.track_order button[type^="submit"]:hover,
.woocommerce-MyAccount-content form button[type^="submit"]:hover,
.woocommerce form.woocommerce-ResetPassword.lost_reset_password button[type^="submit"]:hover,
.load-more-wrapper .button:hover,
.ts-shop-load-more .button:hover,
.woocommerce .ts-shop-load-more .button:hover,
.woocommerce-cart .return-to-shop a.button:hover,
.widget_shopping_cart .buttons a.checkout,
.woocommerce #customer_login form.login .button:hover,
.woocommerce #customer_login form.register .button:hover,
.yith-woocompare-widget a.compare:hover,
.elementor-widget-wp-widget-yith-woocompare-widget a.compare:hover,
.elementor-widget-wp-widget-woocommerce_widget_cart .buttons a.checkout:hover,
#add_payment_method .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button:hover,
.dropdown-footer>a.button.checkout-button {
    color: #ff002b;
}

.woocommerce div.product form.cart .button.loading:after,
.search-table .search-field.loading~.search-button:before,
.wishlist_table .product-add-to-cart a.add_to_cart.loading:after,
body .woocommerce table.compare-list .add-to-cart td a.loading:after,
.product-group-button-meta>div a.loading:after,
.woocommerce .product-group-button-meta>div a.button.loading:after,
rs-layer .products .product div.loop-add-to-cart .button.loading:after {
    background: #ff002b !important;
}

.woocommerce div.product form.cart .button.loading:hover:after,
.wishlist_table .product-add-to-cart a.add_to_cart.loading:hover:after,
body .woocommerce table.compare-list .add-to-cart td a.loading:hover:after,
.product-group-button-meta>div a.loading:hover:after,
.woocommerce .product-group-button-meta>div a.button.loading:hover:after,
rs-layer .products .product div.loop-add-to-cart .button.loading:hover:after {
    background: #ffffff !important;
}

.load-more-wrapper .button.loading,
.ts-shop-load-more .button.loading,
.woocommerce .ts-shop-load-more .button.loading {
    border-top-color: #ff002b;
}

select,
textarea,
html input[type="search"],
html input[type="text"],
html input[type="email"],
html input[type="password"],
html input[type="date"],
html input[type="number"],
html input[type="tel"],
.woocommerce .quantity input.qty,
.quantity input.qty,
body .wishlist-title a.show-title-form,
body .select2-container--default .select2-search--dropdown .select2-search__field,
body .select2-container--default .select2-selectionSingle,
.woocommerce-checkout .select2-dropdown,
body .select2-container--default .select2-selectionSingle,
body .select2-container--default .select2-search--dropdown .select2-search__field,
.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select,
body .select2-container--default .select2-selectionMultiple,
body .select2-container--default .select2-selectionSingle .select2-selection__rendered {
    color: #4f4f4f;
    background-color: #f2f2f2;
}

.vertical-categories-wrapper .products .product-category .product-wrapper:hover,
.ts-active-filters .widget_layered_nav_filters ul li a,
#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box,
.elementor-widget-wp-widget-ts_products.nav-top .ts-slider .owl-nav>div,
.elementor-widget-wp-widget-ts_blogs.nav-top .ts-slider .owl-nav>div,
.elementor-widget-wp-widget-ts_recent_comments.nav-top .ts-slider .owl-nav>div,
.widget-container .ts-slider .owl-nav>div,
#review_form .cr-upload-local-images #cr_review_image,
.cr-upload-local-images #cr_review_image {
    background-color: #f2f2f2;
}

.ts-header nav.vertical-menu>ul.menu>li:hover,
.ts-header nav.vertical-menu>ul.menu>li.ts-active,
.ts-header .normal-menu nav.vertical-menu ul.sub-menu>li:hover,
.ts-header nav.vertical-menu ul li.current-menu-item {
    color: #4f4f4f;
    background-color: #F0F0F0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #f2f2f2 inset !important;
}

body .select2-container--default .select2-selectionSingle .select2-selection__placeholder {
    color: #4f4f4f;
}

body .wishlist-title a.show-title-form:hover {
    background-color: #ff002b;
    color: #ffffff;
}

#add_payment_method #payment div.payment_box::before,
.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before {
    border-bottom-color: #f2f2f2;
}

/*** Border Color ***/
*,
*:before,
*:after,
img,
.entry-meta-bottom,
.commentlist li.comment,
.woocommerce #reviews #comments ol.commentlist li,
.ts-tiny-cart-wrapper .total,
.widget_shopping_cart .total,
.elementor-widget-wp-widget-woocommerce_widget_cart .total,
.twitter-wrapper .avatar-name img,
body.single-post article .entry-format.no-featured-image,
.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th,
.woocommerce div.product form.cart table.group_table td,
.woocommerce form.checkout_coupon,
.woocommerce .checkout-login-coupon-wrapper form.login,
.ts-product-brand-wrapper .item img,
body #yith-woocompare table.compare-list tbody th,
body #yith-woocompare table.compare-list tbody td,
.woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td.product-subtotal,
.ts-header .dropdown-container *,
.color-swatch>div img,
.ts-product-attribute a img,
.ts-header nav>ul.menu ul.sub-menu:before,
.woocommerce div.product.show-tabs-content-default .woocommerce-tabs #reviews nav.woocommerce-pagination,
#main-content.ts-col-24 .woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-images,
#main-content.ts-col-24 .woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-images:after,
#main-content.ts-col-24 .woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items:after {
    border-color: #e5e5e5;
}

.images.loading:after,
.ts-product .content-wrapper.loading:after,
.ts-logo-slider-wrapper.loading .content-wrapper:after,
.related-posts.loading .content-wrapper:after,
.search-table .search-button:after,
.woocommerce .product figure.loading:after,
.ts-products-widget-wrapper.loading:after,
.ts-blogs-widget-wrapper.loading:after,
.ts-recent-comments-widget-wrapper.loading:after,
.blogs article a.gallery.loading:after,
.ts-blogs-wrapper.loading .content-wrapper:after,
.ts-testimonial-wrapper .items.loading:after,
.ts-twitter-slider .items.loading:after,
article .thumbnail.loading:after,
.ts-portfolio-wrapper.loading:after,
.thumbnails.loading:after,
.ts-product-category-wrapper .content-wrapper.loading:after,
.thumbnails-container.loading:after,
.column-products.loading:after,
.ts-team-members .loading:after,
.ts-products-widget-wrapper.loading:after,
.ts-blogs-widget-wrapper.loading:after,
.ts-recent-comments-widget-wrapper.loading:after,
.ts-tiny-cart-wrapper li div.blockUI.blockOverlay:after,
.widget_shopping_cart li div.blockUI.blockOverlay:after,
.elementor-widget-wp-widget-woocommerce_widget_cart div.blockUI.blockOverlay:after,
.dropdown-container ul.cart_list li.loading:after,
.woocommerce a.button.loading:after,
.woocommerce button.button.loading:after,
.woocommerce input.button.loading:after,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a.loading:after,
div.blockUI.blockOverlay:after,
.woocommerce div.blockUI.blockOverlay:after,
.archive.ajax-pagination .woocommerce>.products:before,
div.wpcf7 .ajax-loader:after {
    border-color: #e5e5e5;
}

.images.loading:after,
.search-table .search-button:after,
.ts-product .content-wrapper.loading:after,
.ts-logo-slider-wrapper.loading .content-wrapper:after,
.related-posts.loading .content-wrapper:after,
.woocommerce .product figure.loading:after,
.ts-products-widget-wrapper.loading:after,
.ts-blogs-widget-wrapper.loading:after,
.ts-recent-comments-widget-wrapper.loading:after,
.blogs article a.gallery.loading:after,
.ts-blogs-wrapper.loading .content-wrapper:after,
.ts-testimonial-wrapper .items.loading:after,
.ts-twitter-slider .items.loading:after,
article .thumbnail.loading:after,
.ts-portfolio-wrapper.loading:after,
.thumbnails.loading:after,
.ts-product-category-wrapper .content-wrapper.loading:after,
.thumbnails-container.loading:after,
.column-products.loading:after,
.ts-team-members .loading:after,
.ts-products-widget-wrapper.loading:after,
.ts-blogs-widget-wrapper.loading:after,
.ts-recent-comments-widget-wrapper.loading:after,
.ts-tiny-cart-wrapper li div.blockUI.blockOverlay:after,
.widget_shopping_cart li div.blockUI.blockOverlay:after,
.elementor-widget-wp-widget-woocommerce_widget_cart div.blockUI.blockOverlay:after,
.dropdown-container ul.cart_list li.loading:after,
.woocommerce a.button.loading:after,
.woocommerce button.button.loading:after,
.woocommerce input.button.loading:after,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a.loading:after,
div.blockUI.blockOverlay:after,
.woocommerce div.blockUI.blockOverlay:after,
.archive.ajax-pagination .woocommerce>.products:before,
div.wpcf7 .ajax-loader:after {
    border-top-color: #4f4f4f;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a:after,
.woocommerce div.product.color-variation-thumbnail .ts-product-attribute div.option.color a:hover img,
.woocommerce div.product.color-variation-thumbnail .ts-product-attribute div.option.color.selected a img {
    border-color: #4f4f4f;
}

.woocommerce div.product.color-variation-thumbnail .ts-product-attribute div.option.color a:after {
    background: #4f4f4f;
}

/*** Header Color ***/
.ts-store-notice {
    background-color: #FF6D22;
    border-color: #FF6D22;
    color: #ffffff;
}

.header-top,
.header-middle,
.header-bottom {
    background-color: #fafafa;
    border-color: #e5e5e5;
    color: #4f4f4f;
}

.header-contact-info {
    color: #808080;
}

.header-middle .header-right>*:before {
    border-color: #e5e5e5;
}

.ts-header .search-content input[type="text"] {
    color: #4f4f4f;
    background-color: #fff;
}

.ts-megamenu .category-style-vertical .products .product-category .product-wrapper {
    background-color: #ffffff;
}

.ts-megamenu .category-style-vertical .products .product-category.active .product-wrapper,
.ts-megamenu .category-style-vertical .products .product-category .product-wrapper:hover {
    background-color: #F0F0F0;
}

.ts-header .social-icons ul li:hover i,
.ts-megamenu .category-style-vertical .products .product-category .product-wrapper:hover a {
    color: #ff002b;
}

.ts-header .search-content ::-webkit-input-placeholder {
    color: #4d4d4d;
}

.ts-header .search-content :-moz-placeholder {
    color: #4d4d4d;
}

.ts-header .search-content ::-moz-placeholder {
    color: #4d4d4d;
}

.ts-header .search-content :-ms-input-placeholder {
    color: #4d4d4d;
}

.ts-header nav>ul.menu li ul.sub-menu:before,
.ts-header nav>ul.menu li ul.sub-menu:after,
.ts-header .vertical-menu-wrapper:not(.normal-menu) nav.vertical-menu:before,
.ts-header .vertical-menu>ul,
.ts-header .vertical-menu>ul>li,
.ts-header .vertical-menu li:not(.ts-megamenu)>ul.sub-menu>li {
    background-color: #ffffff;
    border-color: #e5e5e5;
}

.ts-header nav>ul.menu>li.has-line,
.ts-header nav>ul>li.has-line,
.ts-header *,
.ts-header *:before,
.ts-header *:after,
ul.sub-menu .tab-vertical-menu .products .product-category,
ul.sub-menu .category-style-vertical .products .product-category {
    border-color: #e5e5e5;
}

.ts-header .menu-wrapper .vertical-menu,
.ts-header nav>ul.menu li ul.sub-menu,
ul.sub-menu .elementor-element .list-categories ul li a,
ul.sub-menu .elementor-element .heading-title,
.header-contact-info strong {
    color: #4f4f4f;
}

.ts-header .social-icons ul li i {
    color: #4f4f4f;
}

.icon-menu-sticky-header .icon:before,
.search-button.search-icon .icon:before,
.my-wishlist-wrapper .tini-wishlist:before,
.my-notification-wrapper i:before,
.shopping-cart-wrapper .cart-control .ic-cart:before,
.shopping-cart-wrapper .cart-control .cart-total,
.ts-tiny-account-wrapper .account-control>a:before,
.header-transparent.header-text-light .is-sticky .header-middle .icon-menu-sticky-header .icon:before,
.header-transparent.header-text-light .is-sticky .header-middle .search-button.search-icon .icon:before,
.header-transparent.header-text-light .is-sticky .header-middle .my-wishlist-wrapper .tini-wishlist:before,
.header-transparent.header-text-light .is-sticky .header-middle .shopping-cart-wrapper .cart-control .ic-cart:before,
.header-transparent.header-text-light .is-sticky .header-middle .ts-tiny-account-wrapper .account-control>a:before {
    color: #4f4f4f;
}

.icon-menu-sticky-header:hover .icon:before,
.search-button.search-icon:hover .icon:before,
.my-wishlist-wrapper:hover .tini-wishlist:before,
.my-notification-wrapper:hover .tini-wishlist i:before,
.shopping-cart-wrapper:hover .cart-control .ic-cart:before,
.shopping-cart-wrapper:hover .cart-control .cart-total,
.ts-tiny-account-wrapper:hover .account-control>a:before,
.header-transparent.header-text-light .icon-menu-sticky-header:hover .icon:before,
.header-transparent.header-text-light .search-button.search-icon:hover .icon:before,
.header-transparent.header-text-light .my-wishlist-wrapper:hover .tini-wishlist:before,
.header-transparent.header-text-light .shopping-cart-wrapper:hover .cart-control .ic-cart:before,
.header-transparent.header-text-light .shopping-cart-wrapper:hover .cart-control .cart-total,
.header-transparent.header-text-light .ts-tiny-account-wrapper:hover .account-control>a:before {
    color: #ff002b;
}

.my-wishlist-wrapper .tini-wishlist .count-number,
.shopping-cart-wrapper .cart-control .cart-number,
.dropdown-container .theme-title span {
    background: #ff002b;
    color: #ffffff;
}

.header-v4 .header-middle .header-left>*,
.header-v4 .header-middle .header-right>*,
.header-v4 .header-middle .header-left .ts-search-by-category,
.header-v4.header-transparent .is-sticky .header-middle .header-left .ts-search-by-category,
.header-v4.header-transparent .is-sticky .header-middle .header-left>*,
.header-v4.header-transparent .is-sticky .header-middle .header-right>* {
    background-color: #F0F0F0;
}

.header-v4.header-transparent .header-middle .header-left .ts-search-by-category,
.header-v4.header-transparent .header-middle .header-left>*,
.header-v4.header-transparent .header-middle .header-right>* {
    background-color: #ffffff;
}

/*** Breadcrumbs ***/
.breadcrumb-title-wrapper {
    background-color: #fafafa;
}

.breadcrumb-title-wrapper .breadcrumbs,
.breadcrumb-title-wrapper .page-title {
    color: #4f4f4f;
}

.breadcrumb-title-wrapper .breadcrumbs a,
.breadcrumb-title-wrapper .brn_arrow:before,
.breadcrumb-title-wrapper .breadcrumbs-container>span:not(.current):before,
.breadcrumb-title-wrapper.breadcrumb-v1 .description {
    color: #808080;
}

/*** Footer ***/
footer#colophon {
    background-color: #1A1A1A;
    color: #ffffff;
}

footer#colophon,
.footer-container * {
    border-color: #383838;
}

.footer-container .elementor-widget-divider {
    --divider-color: #383838;
}

/*** Product ***/
.column-tabs .list-categories,
.woocommerce .products .product:not(.product-category) .product-wrapper,
.product-hover-vertical-style-2 .products .product:not(.product-category) .product-wrapper:hover .product-group-button-meta {
    background-color: #ffffff;
}

.product-name,
h3.product-name,
.product-name h3,
.product_list_widget .title,
ul.product_list_widget li a,
.woocommerce ul.cart_list li a,
.woocommerce ul.product_list_widget li a,
.price,
.product-price,
.woocommerce div.product p.price,
.woocommerce div.product span.price,
ul.product_list_widget .product-categories,
.price del,
td[data-title="Price"] del,
table.group_table del,
.product-price del,
body .wishlist_table.mobile table.item-details-table td del {
    color: #4f4f4f;
}

.price ins,
td[data-title="Price"] ins,
table.group_table ins,
body .wishlist_table.mobile table.item-details-table td ins,
.product-price ins {
    color: #FF6D22;
}

.star-rating::before,
.woocommerce .star-rating::before,
.woocommerce p.stars a,
.woocommerce p.stars a:hover~a,
.woocommerce p.stars.selected a.active~a,
.ts-testimonial-wrapper .rating:before,
blockquote .rating:before {
    color: #cccccc !important;
}

.star-rating span,
.woocommerce .star-rating span,
.product_list_widget .star-rating span,
.woocommerce p.stars:hover a,
.woocommerce p.stars.selected a,
.woocommerce .star-rating span:before,
.ts-testimonial-wrapper .rating span:before,
blockquote .rating span:before {
    color: #FF6D22 !important;
}

.ts-product-deals-wrapper .content-wrapper,
.woocommerce.main-products>.products>.list-categories {
    background-color: #FFF6EC;
    border-color: #FF6D22;
}

.meta-wrapper .counter-wrapper .number,
.counter-wrapper>div .number>span {
    background-color: #FF6D22;
    color: #ffffff;
}

.counter-wrapper .separate {
    color: #FF6D22;
}

.woocommerce table.shop_table th,
.woocommerce table.shop_table td:not(.actions),
.woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item,
.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce form.checkout_coupon,
.woocommerce .checkout-login-coupon-wrapper form.login,
.woocommerce>form.checkout #customer_details,
.woocommerce>form.checkout #order_review_heading,
.woocommerce>form.checkout #order_review,
.woocommerce-cart article .woocommerce .cart-collaterals,
.woocommerce-checkout #order_review,
.woocommerce #customer_login,
.woocommerce form.track_order,
body .wishlist_table.mobile li,
.woocommerce.ts-search-result-container ul.product_list_widget li,
.ts-floating-sidebar .woocommerce ul.product_list_widget li,
#ts-search-sidebar .search-table .search-field input[type="text"],
.woocommerce-account .woocommerce-MyAccount-content,
.woocommerce-account .woocommerce-MyAccount-navigation li:not(:hover):not(.is-active) a,
.woocommerce form.woocommerce-ResetPassword.lost_reset_password,
.woocommerce div.product.product-style-3 .product-images-summary,
.woocommerce div.product.product-style-3 .ts-product-brand-info,
.woocommerce div.product.product-style-4 .product-images-summary,
.summary-column-2 .ts-product-attribute>div.option:not(.color)>a,
.woocommerce .summary-column-2 .quantity input.qty,
.summary-column-2 .quantity input.qty,
.ts-product-brand-info .brand-info-wrapper,
.ts-product-brand-info .product_list_widget li,
.ts-product.style-list .shortcode-heading-wrapper {
    background-color: #ffffff;
}

body>#ts-search-result-container,
.woocommerce-page.archive #main,
.woocommerce-cart #main,
.woocommerce-checkout #main,
.woocommerce-account #main,
.woocommerce-wishlist #main,
div.product .summary-column-2,
.woocommerce .cross-sells:before,
.woocommerce .up-sells:before,
.woocommerce .related:before,
.woocommerce div.product>.ts-product-in-category-tab-wrapper:before,
#ts-search-sidebar .ts-sidebar-content,
article.single-post .single-related-wrapper {
    background-color: #f7f7f7;
}

@media only screen and (max-width: 1279px) {
    #ts-filter-widget-area .ts-sidebar-content {
        background-color: #f7f7f7;
    }
}

.woocommerce div.product div.summary * {
    border-color: #f0f2f5;
}

.woocommerce.yith-wfbt-section .yith-wfbt-images td>a,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-submit-block,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items:after,
.woocommerce .widget_price_filter .price_slider_amount .price_label>span:first-child:before,
.woocommerce .widget_price_filter .price_slider_amount .price_label>span:last-child,
.woocommerce div.product.product-style-3 .woocommerce-tabs:before,
.woocommerce div.product.product-style-3 .ts-mailchimp-subscription-shortcode:before,
.woocommerce div.product.product-style-3 .ts-instagram-shortcode:before,
.woocommerce div.product.product-style-4 .woocommerce-tabs:before,
.woocommerce div.product.product-style-4 .ts-mailchimp-subscription-shortcode:before,
.woocommerce div.product.product-style-4 .ts-instagram-shortcode:before,
.ts-active-filters .widget_layered_nav_filters ul li a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span,
.widget_product_categories ul.product-categories li>a,
.widget_product_categories ul.product-categories li>span,
.ts-product-categories-widget-wrapper ul.product-categories li>a,
.ts-product-categories-widget-wrapper ul.product-categories li>span:not(.icon-toggle),
.ts-product-categories-widget-wrapper>ul li.cat-parent>span.icon-toggle {
    background: #ffffff !important;
}

.woocommerce-widget-layered-nav ul li:hover>a,
.woocommerce-widget-layered-nav ul li:hover>span,
.ts-active-filters .widget_layered_nav_filters ul li:hover a,
.ts-active-filters .widget_layered_nav_filters ul li.chosen a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item:hover a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item:hover span,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item.chosen a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item.chosen span,
.widget_product_categories ul.product-categories li:hover>a,
.widget_product_categories ul.product-categories li:hover>span,
.widget_product_categories ul.product-categories li.current-cat>a,
.widget_product_categories ul.product-categories li.current-cat>span,
.ts-product-categories-widget-wrapper ul.product-categories li:hover>a,
.ts-product-categories-widget-wrapper ul.product-categories li:hover>span:not(.icon-toggle),
.ts-product-categories-widget-wrapper ul.product-categories li.current>a,
.ts-product-categories-widget-wrapper ul.product-categories li.current>span:not(.icon-toggle),
.ts-product-categories-widget-wrapper>ul li.cat-parent.current>span.icon-toggle {
    background: #ECFDEE !important;
    border-color: #ff002b !important;
}

@media only screen and (min-width: 1279px) {

    .show-filter-top .product-filter-by-brand ul li label,
    .show-filter-top .product-filter-by-availability ul li label,
    .show-filter-top .product-filter-by-color ul li a {
        background: #ffffff !important;
    }

    .show-filter-top .product-filter-by-brand ul li.selected label,
    .show-filter-top .product-filter-by-brand ul li:hover label,
    .show-filter-top .product-filter-by-availability ul li:hover label,
    .show-filter-top .product-filter-by-availability ul li.selected label,
    .show-filter-top .product-filter-by-color ul li:hover a,
    .show-filter-top .product-filter-by-color ul li.chosen a {
        background: #ECFDEE !important;
        border-color: #ff002b !important;
    }
}

/*** Product Label ***/
.product_list_widget .product-label .onsale,
.woocommerce .product .product-label .onsale {
    color: #ffffff;
    background: #ff002b;
}

.product_list_widget .product-label .new,
.woocommerce .product .product-label .new {
    color: #ffffff;
    background: #FE7934;
}

.product_list_widget .product-label .featured,
.woocommerce .product .product-label .featured {
    color: #ffffff;
    background: #CB1800;
}

.product_list_widget .product-label .out-of-stock,
.woocommerce .product .product-label .out-of-stock {
    color: #ffffff;
    background: #9a9a9a;
}

/*** Mobile Buttons Bottom ***/
#ts-mobile-button-bottom {
    background: #ffffff;
    border-color: #d9d9d9;
}

#ts-mobile-button-bottom>div .icon:before,
#ts-mobile-button-bottom .my-wishlist-wrapper .tini-wishlist:before,
#ts-mobile-button-bottom .shopping-cart-wrapper .cart-control .ic-cart:before,
#ts-mobile-button-bottom .ts-tiny-account-wrapper .account-control>a:before {
    color: #4f4f4f;
}

#ts-mobile-button-bottom .my-wishlist-wrapper .tini-wishlist .count-number,
#ts-mobile-button-bottom .shopping-cart-wrapper .cart-control .cart-number {
    background: #ff002b;
    border-color: #ff002b;
    color: #ffffff;
}

#group-icon-header .ts-sidebar-content {
    background-color: #ffffff;
}

#group-icon-header .ts-sidebar-content,
.mobile-menu-wrapper,
.mobile-menu-wrapper .mobile-menu {
    color: #4f4f4f;
}